import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { useState } from "GlobalShared/haunted/CustomHooks";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
export const TEMPLATE_FORMAT_OPTIONS = [
  { label: "xlsx", value: "xlsx" },
  { label: "csv", value: "csv" },
  { label: "txt", value: "txt" },
  { label: "txt(SAP)", value: "txt(SAP" }
];
export const DELIMITER_OPTIONS = [
  { label: "comma(,)", value: "comma(,)" },
  { label: "semicolon(;)", value: "semicolon(;)" },
  { label: "space", value: "space" },
  { label: "tab", value: "tab" }
];
export const NUMBER_DECIMAL_SYMBOL_OPTIONS = [
  { label: "comma(,)", value: "comma(,)" },
  { label: "dot(.)", value: "dot(.)" }
];
export function useCreateDataTemplateStep(props) {
  const [templateFormat, setTemplateFormat] = useState("xlsx");
  const [delimiter, setDelimiter] = useState("comma(,)");
  const [decimalSymbol, setDecimalSymbol] = useState("comma(,)");
  const [allTransactionsInOneFile, setAllTransactionsInOneFile] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const template = () => html`
		<div class="m-8 flex flex-col gap-4">
			${templateTitle(i18next.t("Create Data Template"))}
			<div class="semibold text-lg text-brand-primary">${i18next.t("Template information")}</div>
			<dc-input
				class="w-80"
				.label=${i18next.t("Data Template Name")}
				.value=${templateName}
				.placeholder=${i18next.t("Enter template name")}
				@change=${(e) => setTemplateName(e.detail.value)}
			></dc-input>
			<dc-multiswitch
				.label=${i18next.t("What is the format of the template?")}
				.customLabelClass=${"block text-gray-800 text-xl font-bold mb-2"}
				.dataSource=${TEMPLATE_FORMAT_OPTIONS}
				.selectedItem=${templateFormat}
				@change=${(e) => setTemplateFormat(e.detail.value)}
			></dc-multiswitch>
			<dc-multiswitch
				.label=${i18next.t("Select the delimiter type")}
				.customLabelClass=${"block text-gray-800 text-xl font-bold mb-2"}
				.dataSource=${DELIMITER_OPTIONS}
				.selectedItem=${delimiter}
				@change=${(e) => setDelimiter(e.detail.value)}
			></dc-multiswitch>
			<dc-multiswitch
				.label=${i18next.t("Number decimal symbol")}
				.customLabelClass=${"block text-gray-800 text-xl font-bold mb-2"}
				.dataSource=${NUMBER_DECIMAL_SYMBOL_OPTIONS}
				.selectedItem=${decimalSymbol}
				@change=${(e) => setDecimalSymbol(e.detail.value)}
			></dc-multiswitch>
			<dc-checkbox2
				.checked=${allTransactionsInOneFile}
				.label=${i18next.t("I wish to upload all transactions in one file")}
				@change=${(e) => {
    setAllTransactionsInOneFile(e.detail.checked);
  }}
			></dc-checkbox2>
			<div class="w-full flex justify-end">${PrimaryButton({ class: "w-32 my-6", text: i18next.t("Next"), onClick: () => props.setStep(1) })}</div>
		</div>
	`;
  return { template };
}
