var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { BusinessOwnersAnswer as WebBusinessOwnerAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { BusinessOwnersAnswer as AdminBusinessOwnerAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { newUniqueId } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
import { BusinessOwner as WebBusinessOwner } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/BusinessOwner";
import { BusinessOwner as AdminBusinessOwner } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/BusinessOwner";
import { BusinessOwnerShareholderType } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/BusinessOwner/BusinessOwner";
import { BusinessOwnersValidator, BusinessOwnerValidator } from "./AnswerValidators";
import { templateTitle } from "GlobalShared/templates/commons";
import { checkAnswerDate } from "./answer-helpers";
import "./q11e-add-modal.scss";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-business-owners";
const DEFAULTS = {
  validation: {
    required: false
  }
};
export class BusinessOwnersChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
const NATURAL_PERSON = { label: i18next.t("Natural Person"), value: "NaturalPerson" };
export const SHAREHOLDER_SELECT_ITEMS = [{ label: i18next.t("Company"), value: "Company" }, NATURAL_PERSON];
const IDENTICATION_DOCUMENT_TYPES = [
  { label: i18next.t("Passport"), value: "Passport" },
  { label: i18next.t("Personal Identity card"), value: "PersonalId" },
  { label: i18next.t("Driving License"), value: "DrivingLicense" }
];
export const DEFAULT_BUSINESS_OWNER = {
  Id: void 0,
  ShareholderType: void 0,
  FirstName: "",
  LastName: "",
  FullName: "",
  DateOfBirth: void 0,
  DateOfIncorporation: void 0,
  SharePercentage: void 0,
  DocumentNumber: void 0,
  DocumentType: void 0,
  RegistrationNumber: void 0
};
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation
  };
  const getLabel = (fieldName, required = false) => {
    var _a;
    return ((_a = getSubQuestion(fieldName)) == null ? void 0 : _a.Label) + (required ? "*" : "");
  };
  const getSubQuestion = (questionId) => {
    const filteredSubQuestions = question.SubQuestions.filter((subQuestion) => subQuestion.Id === questionId);
    if (filteredSubQuestions.length === 1) {
      return filteredSubQuestions[0];
    } else {
      return void 0;
    }
  };
  const initForm = () => {
    businessOwnerValidationContext.clearFieldValidations();
  };
  const getAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminBusinessOwnerAnswer({
        Value: businessOwners.map((businessOwnerViewModel) => new AdminBusinessOwner(__spreadValues({}, businessOwnerViewModel))),
        QuestionId: props.questionId
      });
    }
    return new WebBusinessOwnerAnswer({
      Value: businessOwners.map((businessOwnerViewModel) => new WebBusinessOwner(__spreadValues({}, businessOwnerViewModel))),
      QuestionId: props.questionId
    });
  };
  const editBusinessOwner = (id) => {
    initForm();
    setCurrentBusinessOwner(businessOwners.filter((businessOwner) => businessOwner.Id === id)[0]);
    setShowModal(true);
  };
  const removeBusinessOwner = (id) => {
    setDispatchIfValid(true);
    setBusinessOwners(businessOwners.filter((businessOwner) => businessOwner.Id !== id));
  };
  const saveBusinessOwner = () => {
    if (businessOwnerValidationContext.validationResult.isValid()) {
      setDispatchIfValid(true);
      if (currentBusinessOwner.Id === void 0) {
        currentBusinessOwner.Id = newUniqueId();
        setBusinessOwners([...businessOwners, currentBusinessOwner]);
      } else {
        setBusinessOwners(businessOwners.map((businessOwner) => businessOwner.Id === currentBusinessOwner.Id ? currentBusinessOwner : businessOwner));
      }
      setCurrentBusinessOwner(void 0);
    } else {
      businessOwnerValidationContext.validateFields([
        "shareholder_type",
        "first_name",
        "last_name",
        "full_name",
        "date_of_birth",
        "date_of_incorporation",
        "share_percentage",
        "document_type",
        "document_number",
        "registration_number"
      ]);
      businessOwnerValidationContext.validate(new WebBusinessOwner(__spreadValues({}, currentBusinessOwner)));
      setCurrentBusinessOwner(__spreadValues({}, currentBusinessOwner));
    }
  };
  const cancelBusinessOwner = () => {
    setCurrentBusinessOwner(void 0);
  };
  const startAddingBusinessOwner = () => {
    initForm();
    setCurrentBusinessOwner(__spreadValues({}, DEFAULT_BUSINESS_OWNER));
    setShowModal(true);
  };
  const [businessOwners, setBusinessOwners] = useState([]);
  const [currentBusinessOwner, setCurrentBusinessOwner] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new BusinessOwnersValidator(props.validation, props.q11e));
  const businessOwnerValidationContext = useValidationContext(new BusinessOwnerValidator(props.q11e));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new BusinessOwnersChangeEvent({
          answer: getAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getAnswer());
  }, [businessOwners]);
  useEffect(() => {
    businessOwnerValidationContext.validate(new WebBusinessOwner(__spreadValues({}, currentBusinessOwner)));
  }, [currentBusinessOwner]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebBusinessOwnerAnswer || answer instanceof AdminBusinessOwnerAnswer) {
        setBusinessOwners(
          answer.Value.map((businessOwner) => __spreadProps(__spreadValues({}, businessOwner), {
            Id: newUniqueId()
          }))
        );
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    if (!showModal) {
      cancelBusinessOwner();
    }
  }, [showModal]);
  const templateBusinessOwner = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
    return html`${showModal ? html`<div>
					<dc-modal
						.visible=${showModal}
						@close=${() => setShowModal(false)}
						.header=${i18next.t("Business Owners")}
						.content=${html`<div>
							<div class="grid md:grid-cols-2 gap-8 mt-8">
								<dc-select
									class=${((_a = props.q11e) == null ? void 0 : _a.isVisible("business_owner", "shareholder_type")) ? "" : "hidden"}
									.label=${getLabel("shareholder_type", true)}
									.selectedValues=${[currentBusinessOwner.ShareholderType]}
									.dataSource=${((_b = props == null ? void 0 : props.q11e) == null ? void 0 : _b.isIndividual()) ? [NATURAL_PERSON] : SHAREHOLDER_SELECT_ITEMS}
									.readonly=${props.q11e.isReadonly()}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("shareholder_type")}
									@change=${(e) => {
      businessOwnerValidationContext.validateField("shareholder_type");
      setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), {
        ShareholderType: e.detail.selectedValue
      }));
    }}
								></dc-select>
								<dc-input
									class=${`${((_c = props.q11e) == null ? void 0 : _c.isVisible("business_owner", "first_name")) && currentBusinessOwner.ShareholderType === BusinessOwnerShareholderType.NaturalPerson ? "" : "hidden"}`}
									.readonly=${props.q11e.isReadonly()}
									.label=${getLabel("first_name", true)}
									.value=${currentBusinessOwner.FirstName}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("first_name")}
									@change=${(e) => {
      var _a2;
      businessOwnerValidationContext.validateField("first_name");
      setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), {
        FirstName: (_a2 = e.detail) == null ? void 0 : _a2.value
      }));
    }}
								></dc-input>
								<dc-input
									class=${`${((_d = props.q11e) == null ? void 0 : _d.isVisible("business_owner", "last_name")) && currentBusinessOwner.ShareholderType === BusinessOwnerShareholderType.NaturalPerson ? "" : "hidden"}`}
									.readonly=${props.q11e.isReadonly()}
									.label=${getLabel("last_name", true)}
									.value=${currentBusinessOwner.LastName}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("last_name")}
									@change=${(e) => {
      var _a2;
      businessOwnerValidationContext.validateField("last_name");
      setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), { LastName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
								></dc-input>
								<dc-input
									class=${`${((_e = props.q11e) == null ? void 0 : _e.isVisible("business_owner", "full_name")) && currentBusinessOwner.ShareholderType === BusinessOwnerShareholderType.Company ? "" : "hidden"}`}
									.readonly=${props.q11e.isReadonly()}
									.label=${getLabel("full_name", true)}
									.value=${currentBusinessOwner.FullName}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("full_name")}
									@change=${(e) => {
      var _a2;
      businessOwnerValidationContext.validateField("full_name");
      setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), { FullName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
								></dc-input>
								<dc-datepicker
									class=${`${((_f = props.q11e) == null ? void 0 : _f.isVisible("business_owner", "date_of_birth")) && currentBusinessOwner.ShareholderType === BusinessOwnerShareholderType.NaturalPerson ? "" : "hidden"}`}
									.readonly=${props.q11e.isReadonly()}
									.label=${getLabel("date_of_birth", true)}
									.value=${currentBusinessOwner.DateOfBirth}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("date_of_birth")}
									@change=${(e) => {
      if (checkAnswerDate(e.detail.value)) {
        businessOwnerValidationContext.validateField("date_of_birth");
        setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), {
          DateOfBirth: e.detail.value
        }));
      }
    }}
								></dc-datepicker>
								<dc-select
									class=${`${((_g = props.q11e) == null ? void 0 : _g.isVisible("business_owner", "document_type")) && currentBusinessOwner.ShareholderType === BusinessOwnerShareholderType.NaturalPerson ? "" : "hidden"}`}
									.label=${getLabel("document_type", true)}
									.selectedValues=${[currentBusinessOwner.DocumentType]}
									.dataSource=${IDENTICATION_DOCUMENT_TYPES}
									.readonly=${props.q11e.isReadonly()}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("document_type")}
									@change=${(e) => {
      businessOwnerValidationContext.validateField("document_type");
      setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), {
        DocumentType: e.detail.selectedValue
      }));
    }}
								></dc-select>
								<dc-input
									class=${`${((_h = props.q11e) == null ? void 0 : _h.isVisible("business_owner", "document_number")) && currentBusinessOwner.ShareholderType === BusinessOwnerShareholderType.NaturalPerson ? "" : "hidden"}`}
									.readonly=${props.q11e.isReadonly()}
									.label=${getLabel("document_number", true)}
									.value=${currentBusinessOwner.DocumentNumber}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("document_number")}
									@change=${(e) => {
      var _a2;
      businessOwnerValidationContext.validateField("document_number");
      setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), {
        DocumentNumber: (_a2 = e.detail) == null ? void 0 : _a2.value
      }));
    }}
								></dc-input>

								<dc-input
									class=${`${((_i = props.q11e) == null ? void 0 : _i.isVisible("business_owner", "registration_number")) && currentBusinessOwner.ShareholderType === BusinessOwnerShareholderType.Company ? "" : "hidden"}`}
									.readonly=${props.q11e.isReadonly()}
									.label=${getLabel("registration_number", true)}
									.value=${currentBusinessOwner.RegistrationNumber}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("registration_number")}
									@change=${(e) => {
      var _a2;
      businessOwnerValidationContext.validateField("registration_number");
      setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), {
        RegistrationNumber: (_a2 = e.detail) == null ? void 0 : _a2.value
      }));
    }}
								></dc-input>
								<dc-datepicker
									class=${`${((_j = props.q11e) == null ? void 0 : _j.isVisible("business_owner", "date_of_incorporation")) && currentBusinessOwner.ShareholderType === BusinessOwnerShareholderType.Company ? "" : "hidden"}`}
									.readonly=${props.q11e.isReadonly()}
									.label=${getLabel("date_of_incorporation", true)}
									.value=${currentBusinessOwner.DateOfIncorporation}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("date_of_incorporation")}
									@change=${(e) => {
      if (checkAnswerDate(e.detail.value)) {
        businessOwnerValidationContext.validateField("date_of_incorporation");
        setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), {
          DateOfIncorporation: e.detail.value
        }));
      }
    }}
								></dc-datepicker>
								<dc-input
									class=${`${((_k = props.q11e) == null ? void 0 : _k.isVisible("business_owner", "share_percentage")) ? "" : "hidden"}`}
									.readonly=${props.q11e.isReadonly()}
									.label=${getLabel("share_percentage", true)}
									.value=${currentBusinessOwner.SharePercentage}
									.validationMessage=${businessOwnerValidationContext.getValidationMessage("share_percentage")}
									.type=${"number"}
									@change=${(e) => {
      var _a2;
      businessOwnerValidationContext.validateField("share_percentage");
      setCurrentBusinessOwner(__spreadProps(__spreadValues({}, currentBusinessOwner), {
        SharePercentage: Number((_a2 = e.detail) == null ? void 0 : _a2.value)
      }));
    }}
								></dc-input>
							</div>
							<div class="mt-8">
								<button class="btn btn-primary" @click=${cancelBusinessOwner}>${i18next.t("Cancel")}</button>
								${props.q11e.isReadonly() ? "" : html`<button class="btn btn-primary ml-4" @click=${saveBusinessOwner}>${i18next.t("Save")}</button>`}
							</div>
						</div>`}
						.customClass=${"q11e-add-modal"}
					>
					</dc-modal>
			  </div>` : html``}`;
  };
  const templateBusinessOwners = () => {
    return html`<div>
				${businessOwners.length > 0 ? businessOwners.map(
      (businessOwner) => html`<div class="flex justify-between py-2">
										<div>
											<a class="link" href="javascript:void(0);" @click=${() => editBusinessOwner(businessOwner.Id)}
												>${businessOwner.ShareholderType === BusinessOwnerShareholderType.Company ? businessOwner.FullName : html`${businessOwner.LastName}, ${businessOwner.FirstName}`}
											</a>
										</div>
										${props.q11e.isReadonly() ? "" : html`<div>
													<a class="link" href="javascript:void(0);" @click=${() => removeBusinessOwner(businessOwner.Id)}>${i18next.t("Remove")}</a>
											  </div>`}
									</div>
									<hr />`
    ) : html`${currentBusinessOwner === void 0 ? html`<div class="italic mb-2">
									${i18next.t("Please add at least one business owner.")} ${(businessOwners == null ? void 0 : businessOwners.length) === 0 ? i18next.t("No business owner has been added yet.") : ""}
							  </div>` : ""}`}
			</div>
			<div class="mt-2">
				${currentBusinessOwner !== void 0 ? templateBusinessOwner() : ""}
				${props.q11e.isReadonly() ? "" : html`<button class="btn btn-primary" @click=${startAddingBusinessOwner}>${i18next.t("Add new Business Owners")}</button>`}
			</div>`;
  };
  return html`${props.q11e !== void 0 && question !== void 0 ? html` <div>
				<div class="mt-10 mb-2">${templateTitle(i18next.t("Business Owners"))}</div>
				${templateBusinessOwners()}
		  </div>` : ""}`;
};
