export var PeriodSearchResultStatus = /* @__PURE__ */ ((PeriodSearchResultStatus2) => {
  PeriodSearchResultStatus2["New"] = "New";
  PeriodSearchResultStatus2["DataUploaded"] = "DataUploaded";
  PeriodSearchResultStatus2["ValidationError"] = "ValidationError";
  PeriodSearchResultStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodSearchResultStatus2["DocumentsGenerated"] = "DocumentsGenerated";
  PeriodSearchResultStatus2["Error"] = "Error";
  PeriodSearchResultStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodSearchResultStatus2["AcceptedByTa"] = "AcceptedByTa";
  PeriodSearchResultStatus2["Submitted"] = "Submitted";
  PeriodSearchResultStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  return PeriodSearchResultStatus2;
})(PeriodSearchResultStatus || {});
export var PeriodSearchResultUploadType = /* @__PURE__ */ ((PeriodSearchResultUploadType2) => {
  PeriodSearchResultUploadType2["Csv"] = "Csv";
  PeriodSearchResultUploadType2["Xml"] = "Xml";
  PeriodSearchResultUploadType2["Empty"] = "Empty";
  return PeriodSearchResultUploadType2;
})(PeriodSearchResultUploadType || {});
