import { useQueryState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { useCreateDataTemplateStep } from "./useCreateDataTemplateStep";
import { useFieldConfigurationStep } from "./useConfigureFieldsStep";
import { useDemoSetupTaxCodeStep } from "./useDemoSetupTaxCodeStep";
const CREATE_DATA_TEMPLATE_STEPS = [i18next.t("Create Data Template"), i18next.t("Configure Fields"), i18next.t("Setup tax codes")];
export function CustomDataTemplate() {
  const [currentStep, setCurrentStep] = useQueryState("step", 0);
  const createDataTemplateStep = useCreateDataTemplateStep({ setStep: setCurrentStep });
  const fieldConfigurationStep = useFieldConfigurationStep({ setStep: setCurrentStep });
  const setupTaxCodes = useDemoSetupTaxCodeStep({ setStep: setCurrentStep });
  return html`
		<div class="mt-8 flex flex-col">
			<dc-step-progressbar
				.items=${CREATE_DATA_TEMPLATE_STEPS}
				.activeIndex=${currentStep}
				.inactiveClickable=${true}
				@click=${(e) => {
    setCurrentStep(e.detail.step);
  }}
			></dc-step-progressbar>
			${currentStep === 0 ? createDataTemplateStep.template() : ""} ${currentStep === 1 ? fieldConfigurationStep.template() : ""} ${currentStep === 2 ? setupTaxCodes.template() : ""}
		</div>
	`;
}
