import { fileIcon, templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { useQueryState, useState } from "GlobalShared/haunted/CustomHooks";
import { setDataTransfer } from "GlobalShared/ui/dc-dropfield";
import classNames from "classnames";
const TAB_NAMES = {
  sale: "Sale",
  purchase: "Purchase"
};
export const TAB_QUERY_PREFIX = "tab";
export function useFieldConfigurationStep(props) {
  const onTabSelect = (e) => {
    if (e.detail.selectedTab !== selectedTab) {
      setSelectedTab(e.detail.selectedTab);
    }
  };
  const [selectedTab, setSelectedTab] = useQueryState(TAB_QUERY_PREFIX, TAB_NAMES.sale);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const dragFields = [
    {
      id: 4,
      label: "Taxable Basis",
      info: "Taxable Basis",
      FieldType: "Decimal",
      type: "TemplateDataSource"
    },
    {
      id: 1,
      label: "Invoice Date",
      info: "Document Date",
      FieldType: "DateTime",
      type: "TemplateDataSource"
    },
    {
      id: 3,
      label: "Tax Code",
      info: "Tax Code",
      FieldType: "String",
      type: "TemplateDataSource"
    },
    {
      id: 2,
      label: "Supplier VAT number",
      info: "VAT Registration Number",
      FieldType: "String",
      type: "TemplateDataSource"
    }
  ];
  const [pairs, setPairs] = useState(
    /* @__PURE__ */ new Map([
      ["Doc Date", void 0],
      ["VAT Registration No", void 0],
      ["Tx", void 0],
      ["Tax base amount", void 0]
    ])
  );
  const dropfieldTemplate = (dropFieldLabel, index) => html`
		<div class=${classNames("flex px-4 py-2", { "border-brand-primary border-b border-solid": index !== pairs.size - 1 })}>
			<div class="flex items-center justify-start basis-1/2">${dropFieldLabel}</div>
			${pairs.get(dropFieldLabel) ? html`
						<div class="basis-1/2 bg-gray-50 border border-green-500 border-solid rounded-xl px-4 py-2 flex justify-between">
							<span>${pairs.get(dropFieldLabel).label}</span><span>${pairs.get(dropFieldLabel).FieldType}</span>
						</div>
				  ` : html`
						<dc-dropfield
							class="basis-1/2"
							.class=${"px-4 py-2 text-center justify-center border-gray-200 border-2 rounded-xl bg-gray-200 border-solid hover:border-2 hover:border-dashed hover:border-gray-500"}
							.emptyText=${i18next.t("Drop Tax Desk mapping here")}
							.allowedDragTypes=${["TemplateDataSource"]}
							.onDrop=${(e) => {
    const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
    setPairs(new Map(pairs.set(dropFieldLabel, draggedItem)));
  }}
						></dc-dropfield>
				  `}
		</div>
	`;
  const draggableItemTemplate = (label, type, info, id) => html`
		<div
			class="flex cursor-move justify-between border-brand-primary bg-white border border-solid rounded-xl w-[400px] px-4 py-2"
			id=${`draggable-${id}`}
			draggable="true"
			@dragstart=${(e) => {
    const draggedItem = {
      type: "TemplateDataSource",
      id,
      label,
      info,
      FieldType: type
    };
    setDataTransfer(e, draggedItem);
    const element = document.getElementById(`draggable-${id}`);
    element.style.opacity = "0";
    const hideDragImage = element.cloneNode(true);
    hideDragImage.id = "hideDragImage-hide";
    const dragImage = element.cloneNode(true);
    dragImage.id = "draggeimage";
    dragImage.style.position = "absolute";
    hideDragImage.style.opacity = "0";
    document.body.appendChild(hideDragImage);
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(hideDragImage, 0, 0);
  }}
			@dragend=${() => {
    const element = document.getElementById(`draggable-${id}`);
    if (element) {
      element.style.opacity = "1";
    }
    const hideDragImage = document.getElementById("hideDragImage-hide");
    const dragImage = document.getElementById("draggeimage");
    if (hideDragImage) {
      hideDragImage.remove();
    }
    if (dragImage) {
      dragImage.remove();
    }
  }}
			@drag=${(e) => {
    const dragImage = document.getElementById("draggeimage");
    if (dragImage) {
      dragImage.style.opacity = "1";
      dragImage.style.left = e.pageX + "px";
      dragImage.style.top = e.pageY + "px";
    }
  }}
		>
			<div class="flex items-center justify-start basis-1/2">${label}</div>
			<div class="flex gap-4">
				<div>${type}</div>
				<dc-tooltip .label=${info}></dc-tooltip>
			</div>
		</div>
	`;
  const tabTemplate = () => html`
		<div class="flex flex-col gap-8">
			<div class="flex gap-2 items-center">
				<dc-fileupload2
					class="self-start"
					.label=${i18next.t("Upload Sample Data File")}
					.fileReference=${""}
					.fileName=${"Alma"}
					.linkGenerator=${() => {
  }}
					.withLoader=${isFileUploading}
					.loaderPosition=${"right"}
					.showFileLink=${false}
					@upload=${(e) => {
    setIsFileUploading(true);
    window.setTimeout(() => {
      setIsFileUploading(false);
      setUploadedFile(e.detail.files[0]);
    }, 2e3);
  }}
				></dc-fileupload2>
				<div class="flex gap-2">
					${uploadedFile ? fileIcon(uploadedFile == null ? void 0 : uploadedFile.name) : ""}
					<div class="hover:underline">${uploadedFile == null ? void 0 : uploadedFile.name}</div>
				</div>
			</div>
			<div class=${classNames("flex flex-wrap gap-8", { hidden: !uploadedFile })}>
				<div class="flex flex-col gap-2">
					<div class="text-xl font-bold">${i18next.t("Transaction Data fields")}</div>
					<div class="flex self-start flex-col border-brand-primary border border-solid rounded-xl w-full min-w-[600px] max-w-[700px]">
						${Array.from(pairs.keys()).map((dropFieldLabel, index) => dropfieldTemplate(dropFieldLabel, index))}
					</div>
				</div>
				<div class="flex flex-col gap-2">
					<div class="text-xl font-bold">${i18next.t("Tax Desk Data")}</div>
					${dragFields.filter((field) => Array.from(pairs.values()).every((val) => (val == null ? void 0 : val.id) !== (field == null ? void 0 : field.id))).length === 0 ? html`<div class="font-bold">${i18next.t("Congratulations! All data mapped successfully!")}</div>` : ""}
					${dragFields.filter((field) => Array.from(pairs.values()).every((val) => (val == null ? void 0 : val.id) !== (field == null ? void 0 : field.id))).map((field) => draggableItemTemplate(field.label, field.FieldType, field.info, field.id))}
				</div>
			</div>
		</div>
	`;
  const template = () => html`
		<div class="m-8 flex flex-col gap-4">
			<div>${templateTitle(i18next.t("Field Configuration"))}</div>
			<div class="bold text-normal text-brand-primary">
				${i18next.t("The following fields has been captured from the uploaded file(s), please match them with the Tax Desk Data. All Tax Desk Data must be wrapped.")}
			</div>
			<dc-tabs
				.data=${[
    {
      name: TAB_NAMES.sale,
      label: "Sale",
      template: html` <div class="my-6">${tabTemplate()}</div> `
    },
    {
      name: TAB_NAMES.purchase,
      label: "Purchase",
      template: html` <div class="my-6">${tabTemplate()}</div> `
    }
  ]}
				.selectedTab=${selectedTab}
				@tabSelected=${(e) => onTabSelect(e)}
			></dc-tabs>

			<div class="flex w-full flex-wrap justify-between gap-4">
				${SecondaryButton({ class: "w-32 my-6", text: i18next.t("Previous"), onClick: () => props.setStep(0) })}
				${PrimaryButton({ class: "w-32 my-6", text: i18next.t("Next"), onClick: () => props.setStep(2) })}
			</div>
		</div>
	`;
  return { template };
}
