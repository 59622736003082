import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { ONLY_TXT_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
export const useSapTextUpload = (props) => {
  const htmlSapTextUpload = (mode) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
    const currentSapTextPurchaseUploadJob = (_a = props.currentUploadJobs) == null ? void 0 : _a.find((job) => job.type === "SapTextPurchase");
    const isPurchaseErrorFileSameAsValidFile = ((_c = (_b = props.currentComplianceUpload) == null ? void 0 : _b.SapTextPurchaseFile) == null ? void 0 : _c.Reference) === ((_d = currentSapTextPurchaseUploadJob == null ? void 0 : currentSapTextPurchaseUploadJob.latestUploadedFile) == null ? void 0 : _d.Reference);
    const isPurchaseFileSuccessFullyUploaded = ((_f = (_e = props.currentComplianceUpload) == null ? void 0 : _e.SapTextPurchaseFile) == null ? void 0 : _f.Reference) && !isPurchaseErrorFileSameAsValidFile;
    const currentSapTextSaleUploadJob = (_g = props.currentUploadJobs) == null ? void 0 : _g.find((job) => job.type === "SapTextSale");
    const isSaleErrorFileSameAsValidFile = ((_i = (_h = props.currentComplianceUpload) == null ? void 0 : _h.SapTextSaleFile) == null ? void 0 : _i.Reference) === ((_j = currentSapTextSaleUploadJob == null ? void 0 : currentSapTextSaleUploadJob.latestUploadedFile) == null ? void 0 : _j.Reference);
    const isSaleFileSuccessFullyUploaded = ((_l = (_k = props.currentComplianceUpload) == null ? void 0 : _k.SapTextSaleFile) == null ? void 0 : _l.Reference) && !isSaleErrorFileSameAsValidFile;
    return html`
			<div class="mb-4 grid grid-cols-1 lg:grid-cols-2 gap-8">
				<div>
					<div class="text-xl font-bold truncate mb-2">${i18next.t("Upload Sale Transactions")}</div>
					<dc-dropzone-select
						class=${classNames("h-40 rounded-xl bg-gray-100 block mb-3", {
      "hover:border-2 hover:border-dashed hover:border-gray-500": !props.hasAnyOngoingDataProcessingJob() && mode !== "Readonly"
    })}
						.accept=${ONLY_TXT_ACCEPT_LIST}
						.uploadButtonLabel=${i18next.t("Select File")}
						.label=${i18next.t("Drop file here to upload")}
						.dragOverText=${i18next.t("Drop file here to upload")}
						.onFileUpload=${(files) => props.uploadFileToBlob(files[0], "SapTextSale")}
						.readonly=${props.hasAnyOngoingDataProcessingJob()}
					></dc-dropzone-select>
					${props.htmlFileUploadStatus("SapTextSale", isSaleErrorFileSameAsValidFile, (_m = props.currentComplianceUpload) == null ? void 0 : _m.SapTextSaleFile, isSaleFileSuccessFullyUploaded)}
					${props.isDataProcessingJobOngoingForUploadType("SapTextSale") ? html`<dc-loader2 .isLoading=${true}></dc-loader2> ` : ""}
				</div>
				<div>
					<div class="text-xl font-bold truncate mb-2">${i18next.t("Upload Purchase Transactions")}</div>
					<dc-dropzone-select
						class=${classNames("h-40 rounded-xl bg-gray-100 block mb-3", {
      "hover:border-2 hover:border-dashed hover:border-gray-500": !props.hasAnyOngoingDataProcessingJob() && mode !== "Readonly"
    })}
						.accept=${ONLY_TXT_ACCEPT_LIST}
						.uploadButtonLabel=${i18next.t("Select File")}
						.label=${i18next.t("Drop file here to upload")}
						.dragOverText=${i18next.t("Drop file here to upload")}
						.onFileUpload=${(files) => props.uploadFileToBlob(files[0], "SapTextPurchase")}
						.readonly=${props.hasAnyOngoingDataProcessingJob()}
					></dc-dropzone-select>
					${props.htmlFileUploadStatus("SapTextPurchase", isPurchaseErrorFileSameAsValidFile, (_n = props.currentComplianceUpload) == null ? void 0 : _n.SapTextPurchaseFile, isPurchaseFileSuccessFullyUploaded)}
					${props.isDataProcessingJobOngoingForUploadType("SapTextPurchase") ? html`<dc-loader2 .isLoading=${true}></dc-loader2> ` : ""}
				</div>
			</div>
		`;
  };
  return {
    htmlSapTextUpload
  };
};
