var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { isApiError } from "GlobalShared/common/ApiError";
import { ReduxContext } from "GlobalShared/ReduxContext";
import { DcNotificationType } from "GlobalShared/ui/dc-notifier";
import { DcNotifierReducer } from "GlobalShared/ui/dc-notifier-reducer";
import i18next from "i18next";
export const withErrorHandling = (callback, errorHandler, finallyFunc, loader) => __async(void 0, null, function* () {
  const localErrorHandler = (error) => {
    if (error && error.statusCode === 401) {
      globalThis.location.reload();
    } else {
      return errorHandler(error);
    }
  };
  try {
    loader == null ? void 0 : loader.start();
    return yield callback();
  } catch (error) {
    localErrorHandler(error);
  } finally {
    loader == null ? void 0 : loader.stop();
    if (finallyFunc) {
      finallyFunc();
    }
  }
});
export const notifyErrorWithWarning = (error) => {
  if (isApiError(error)) {
    const error2 = extractFirstError(error);
    if (error2 && error.statusCode >= 400 && error.statusCode < 500) {
      notifyWarning(`${error2.message} (${error.statusCode})`);
    } else {
      notifyError(error);
    }
  } else if (error.statusCode >= 400 && error.statusCode < 500 && error.data.Message) {
    notifyWarning(`${error.data.Message} (${error.statusCode})`);
  } else {
    notifyError(error);
  }
};
export const notifyErrorMessage = (errorMessage) => {
  console.log(errorMessage);
  ReduxContext.dispatch(
    DcNotifierReducer.addNotification({
      message: errorMessage,
      type: DcNotificationType.Error,
      autoRemove: true
    })
  );
};
export const notifyError = (error) => {
  let errorMessage;
  if (error instanceof Error) {
    if (error.name !== "AbortError") {
      errorMessage = error.message;
    }
  } else if (error.statusCode) {
    if (error.statusCode === 403) {
      errorMessage = `${i18next.t("Access denied")} (${error.statusCode})`;
    } else if (error.statusCode === 401) {
      errorMessage = `${i18next.t("Not authenticated")} (${error.statusCode})`;
    } else if (error.statusCode === 404) {
      errorMessage = `${i18next.t("Not found")} (${error.statusCode})`;
    } else {
      errorMessage = `${i18next.t("Error occured")} (${error.statusCode})`;
    }
  } else {
    errorMessage = i18next.t("Unknown error occured");
  }
  if (errorMessage !== void 0) {
    console.log(error);
    ReduxContext.dispatch(
      DcNotifierReducer.addNotification({
        message: errorMessage,
        type: DcNotificationType.Error,
        autoRemove: true
      })
    );
  }
};
export const notifySuccess = (message) => {
  ReduxContext.dispatch(
    DcNotifierReducer.addNotification({
      message,
      type: DcNotificationType.Success,
      autoRemove: true
    })
  );
};
export const notifyWarning = (message) => {
  ReduxContext.dispatch(
    DcNotifierReducer.addNotification({
      message,
      type: DcNotificationType.Warning,
      autoRemove: true
    })
  );
};
export const checkError = (error, statusCode, errorCode) => {
  var _a;
  if (error.statusCode === statusCode) {
    if (errorCode === void 0 || ((_a = error.ErrorMessages) == null ? void 0 : _a.length) > 0 && error.ErrorMessages[0].Code === errorCode) {
      return true;
    }
  }
  return false;
};
export const extractFirstErrorMessage = (error, defaultValue) => {
  var _a, _b;
  return (_b = (_a = extractFirstError(error)) == null ? void 0 : _a.message) != null ? _b : defaultValue;
};
export const extractFirstError = (error) => {
  var _a;
  if (isApiError(error)) {
    const err = ((_a = error.ErrorMessages) == null ? void 0 : _a.length) > 0 ? error.ErrorMessages[0] : void 0;
    if (err) {
      return { message: err.Message, code: err.Code };
    }
  }
  return void 0;
};
