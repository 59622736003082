var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useEffect, useMemo, useRef, useState } from "GlobalShared/haunted/CustomHooks";
import { usePeriodEditorTemplates } from "./period-editor-templates";
import { getQuarter } from "GlobalShared/helpers/dateHelper";
import { VALIDATION_ENDED_STATUSES } from "./PeriodModels";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { currentLocale } from "../../../globals";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { PeriodVersionStatus, PeriodVersionUploadType } from "WebServices/APIs/self/models/V1/Cesop/Periods/PeriodVersion";
import i18next from "i18next";
export const usePeriodEditor = ({ refresh, periodManager }) => {
  const close = () => __async(void 0, null, function* () {
    setIsOpen(false);
    setPeriod(void 0);
    window.clearTimeout(timeoutHandlerRef.current);
    yield refresh();
  });
  const open = (periodId) => __async(void 0, null, function* () {
    const response = yield periodManager.get(periodId, true);
    setPeriod(response);
    setIsOpen(true);
  });
  const reload = () => __async(void 0, null, function* () {
    if (period) {
      const response = yield periodManager.get(period.Id, false);
      setPeriod(response);
    }
  });
  const isSourceFileProcessing = () => {
    var _a;
    return [...(_a = period == null ? void 0 : period.CurrentVersion.SourceFiles) != null ? _a : [], period.CurrentVersion.Payees].filter((x) => x).some((sf) => !VALIDATION_ENDED_STATUSES.includes(sf.Status));
  };
  const isDataGenerationInProgress = () => (period == null ? void 0 : period.CurrentVersion.Status) === PeriodVersionStatus.DocumentGenerationInProgress;
  const isCorrectionGenerationInProgress = () => (period == null ? void 0 : period.CurrentVersion.Status) === PeriodVersionStatus.CorrectionNeeded;
  const isPollingNeeded = () => {
    if (!period)
      return false;
    return isSourceFileProcessing() || isDataGenerationInProgress() || isCorrectionGenerationInProgress();
  };
  const finalizeUpload = () => __async(void 0, null, function* () {
    if (period.CurrentVersion.UploadType !== PeriodVersionUploadType.Csv) {
      setWaitingForFinalize(true);
    } else {
      yield finalize();
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState();
  const [waitingForFinalize, setWaitingForFinalize] = useState(false);
  const timeoutHandlerRef = useRef(void 0);
  const countries = useWorldCountries(currentLocale);
  const {
    titleTemplate,
    viewFilesModalTemplate,
    dataUploadedTemplate,
    documentGenerationTemplate,
    documentsGeneratedTemplate,
    submissionInProgressTemplate,
    acceptedByTaTemplate,
    submittedTemplate,
    errorTemplate,
    uploadTemplate,
    correctionNeededTemplate
  } = usePeriodEditorTemplates({
    period,
    periodManager,
    reload,
    refresh,
    finalizeUpload
  });
  const label = useMemo(() => {
    return period ? `Q${getQuarter(period.StartDate)} ${period.StartDate.getFullYear()} | ${countries.find((country) => country.Code === period.ReportingCountry).Name} | ${period.Psp.Name.Value} |${i18next.t("Registration id")}: ${period.RegistrationIdentifier ? period.RegistrationIdentifier : i18next.t("N/A")}` : "";
  }, [period]);
  useEffect(() => {
    if (isPollingNeeded()) {
      timeoutHandlerRef.current = window.setTimeout(() => {
        reload();
      }, 2e3);
      return () => window.clearTimeout(timeoutHandlerRef.current);
    }
    return void 0;
  }, [period]);
  const finalize = () => __async(void 0, null, function* () {
    setWaitingForFinalize(false);
    yield periodManager.finalizeUpload(period);
    yield reload();
    yield refresh();
  });
  const waitingForFinalizeTemplate = (periodVersion) => {
    var _a, _b;
    const xmlContent = html`
			<div class="mt-3 font-semibold">
				${i18next.t(
      "The below xml have been uploaded successfully. Please review the number of files uploaded and approve. If you need to modify the submission please reject and modify the files."
    )}
			</div>
			<div class="mb-3 text-sm">${i18next.t("Total number of files uploaded")}: <span class="font-semibold">${(_b = (_a = periodVersion.SourceFiles) == null ? void 0 : _a.length) != null ? _b : 0}</span></div>
		`;
    const emptyContent = html`
			<div class="mt-3 font-semibold">${i18next.t("By clicking the Approve button the system will generate a nil report and it will be submitted to the country's Tax Authority.")}</div>
			<div class="mb-3 text-sm">${i18next.t("If you would like to report transactions to the Tax Authority, please reject and modify the declaration.")}</div>
		`;
    return html`
			<div class="p-6">
				${titleTemplate(periodVersion)} ${period.CurrentVersion.UploadType === PeriodVersionUploadType.Xml ? xmlContent : emptyContent}
				<div class="flex gap-3 mt-12 items-end">
					${PrimaryButton({ class: "w-32", text: i18next.t("Approve"), onClick: finalize })}
					${SecondaryButton({ class: "w-32", text: i18next.t("Reject"), onClick: () => setWaitingForFinalize(false) })}
				</div>
			</div>
		`;
  };
  const periodVersionsTemplate = () => period == null ? void 0 : period.Versions.sort((a, b) => a.Version > b.Version ? -1 : 1).map((version) => {
    switch (version.Status) {
      case PeriodVersionStatus.DataUploaded:
        return dataUploadedTemplate(version);
      case PeriodVersionStatus.DocumentGenerationInProgress:
        return documentGenerationTemplate(version);
      case PeriodVersionStatus.DocumentsGenerated:
        return documentsGeneratedTemplate(version);
      case PeriodVersionStatus.SubmissionInProgress:
        return submissionInProgressTemplate(version);
      case PeriodVersionStatus.AcceptedByTa:
        return acceptedByTaTemplate(version);
      case PeriodVersionStatus.Submitted:
        return submittedTemplate(version);
      case PeriodVersionStatus.Error:
        return errorTemplate(version);
      case PeriodVersionStatus.New:
        if (waitingForFinalize)
          return waitingForFinalizeTemplate(version);
        return uploadTemplate(version);
      case PeriodVersionStatus.ValidationError:
        return uploadTemplate(version);
      default:
        return correctionNeededTemplate(version);
    }
  });
  const template = period ? html`
				<dc-modal overflowW=${false} .visible=${isOpen} @close=${close} .header=${label} .content=${periodVersionsTemplate()}> </dc-modal>
				${viewFilesModalTemplate(label)}
		  ` : "";
  return { open, template };
};
