var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { ComplianceMasterData as ComplianceMasterDataClient, TransactionTypes as TransactionTypesClient } from "WebServices/APIs/self/clients";
import { AddTaxCode, UpdateTaxCode } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { SearchRequest } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search/SearchRequest";
import { TaxCodeFieldNames } from "GlobalShared/components/taxcodes/TaxCodeModels";
import { Filter, Pager, Field } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search";
import { useSetupTaxCodeStep } from "GlobalShared/components/taxcodes/useSetupTaxCodeStep";
import { FieldOrder } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search/Field";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { currentLocale } from "../../../globals";
import i18next from "i18next";
export function AdminTaxCodes() {
  const getFilter = (searchRequest) => {
    const clientFilter = new Filter();
    if (searchRequest.gridState.appliedFilters.length > 0) {
      searchRequest.gridState.appliedFilters.forEach((filter) => {
        if (filter.filterProps.selectedValues) {
          if (filter.field === TaxCodeFieldNames.ClientTaxCode) {
            clientFilter.ClientTaxCode = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.Country) {
            clientFilter.CountryCode = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.TransactionType) {
            clientFilter.TransactionTypeCodes = [...filter.filterProps.selectedValues];
          } else if (filter.field === TaxCodeFieldNames.VatRate) {
            clientFilter.VatRateTypes = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.TypeOfGoods) {
            clientFilter.TypeOfGoods = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.ReverseCharge) {
            clientFilter.ReverseCharge = filter.filterProps.selectedValues;
          }
        }
      });
    }
    clientFilter.OnlyMissing = searchRequest.onlyMissing ? true : void 0;
    return clientFilter;
  };
  const searchTaxCodes = (searchRequest, loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield complianceMasterDataClient.searchTaxCodes({
          body: new SearchRequest({
            Filter: getFilter(searchRequest),
            Pager: new Pager({
              Skip: searchRequest.gridState.pageIndex * searchRequest.gridState.pageSize,
              Top: searchRequest.gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: searchRequest.gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: searchRequest.gridState.orderBy
                })
              ]
            })
          })
        });
        return result.data;
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const getTypeOfGoods = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield complianceMasterDataClient.getTypeOfGoods();
        return result.data;
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const getVATRateTypeSettings = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield complianceMasterDataClient.getVATRateTypeSettings();
        return result.data;
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const addTaxCode = (taxCode, loader) => __async(this, null, function* () {
    const bodyToPost = new AddTaxCode({
      ClientTaxCode: taxCode.clientTaxCode,
      CountryCode: taxCode.countryCode,
      ReverseCharge: taxCode.reverseCharge,
      TransactionTypeCode: taxCode.transactionTypeCode,
      TypeOfGoods: taxCode.typeOfGoods,
      VatRateType: taxCode.vatRateType
    });
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield complianceMasterDataClient.addTaxCode({ body: bodyToPost });
        notifySuccess(i18next.t("Tax code added successfully"));
        return result.data;
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const updateTaxCode = (id, taxCode, loader) => __async(this, null, function* () {
    const bodyToPost = new UpdateTaxCode({
      ClientTaxCode: taxCode.clientTaxCode,
      CountryCode: taxCode.countryCode,
      ReverseCharge: taxCode.reverseCharge,
      TransactionTypeCode: taxCode.transactionTypeCode,
      TypeOfGoods: taxCode.typeOfGoods,
      VatRateType: taxCode.vatRateType
    });
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.updateTaxCode({ id, body: bodyToPost });
        notifySuccess(i18next.t("Tax code updated successfully"));
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const deleteTaxCode = (id, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.deleteTaxCode({ id });
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const getTransactionTypes = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield transactionTypesClient.get();
        return result.data;
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const transactionTypesClient = useClient(TransactionTypesClient);
  const reportingCountries = useWorldCountries(currentLocale);
  const setupTaxCodeStep = useSetupTaxCodeStep({
    reportingCountries,
    companyId: void 0,
    isAdmin: true,
    searchTaxCodes,
    getTypeOfGoods,
    getVATRateTypeSettings,
    addTaxCode,
    updateTaxCode,
    deleteTaxCode,
    getTransactionTypes
  });
  return setupTaxCodeStep.template();
}
