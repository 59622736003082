var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useMemo, useState } from "GlobalShared/haunted/CustomHooks";
import { getParameter } from "GlobalShared/router";
const MODE = "mode";
export const useCustomMode = () => {
  const CUSTOM_MODE = "custom";
  const customModeParam = useMemo(() => getParameter(MODE) !== void 0 && getParameter(MODE) === CUSTOM_MODE, []);
  const [isCustomMode, setIsCustomMode] = useState(false);
  const init = () => __async(void 0, null, function* () {
    if (sessionStorage.getItem(MODE) === CUSTOM_MODE) {
      setIsCustomMode(true);
    } else {
      setIsCustomMode(false);
    }
  });
  useEffect(() => {
    if (customModeParam) {
      sessionStorage.setItem(MODE, CUSTOM_MODE);
      setIsCustomMode(true);
    }
  }, [customModeParam]);
  useEffect(init, []);
  return isCustomMode;
};
