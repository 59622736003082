var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import i18next from "i18next";
import { html } from "lit-html";
import { ComplianceUploadFieldNames } from "./ComplianceUploadModels";
import moment from "moment";
import { useMemo, useQueryGridState } from "GlobalShared/haunted/CustomHooks";
import { generateMonthlyPeriods } from "GlobalShared/helpers/periodHelper";
import { DEFAULT_FILTERS, getStatusLabel, getType, getTypeFilterDataSource, isFinalizedStatusLike, Statuses } from "./ComplianceUploadsHelper";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { notifyErrorWithWarning, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
export const useComplianceUploadGrid = (props) => {
  var _a, _b;
  const cancel = (complianceUploadId) => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a2, _b2;
        const newStatus = yield props.cancelUpload(complianceUploadId);
        props.setSearchResult({
          total: (_a2 = props.searchResult) == null ? void 0 : _a2.total,
          uploads: (_b2 = props.searchResult) == null ? void 0 : _b2.uploads.map((u) => {
            if (u.Id === complianceUploadId) {
              u.Status = newStatus;
            }
            return u;
          })
        });
        notifySuccess(i18next.t("Upload successfully canceled"));
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  };
  const reconfirm = (complianceUploadId) => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a2, _b2;
        const newStatus = yield props.reconfirmUpload(complianceUploadId);
        props.setSearchResult({
          total: (_a2 = props.searchResult) == null ? void 0 : _a2.total,
          uploads: (_b2 = props.searchResult) == null ? void 0 : _b2.uploads.map((u) => {
            if (u.Id === complianceUploadId) {
              u.Status = newStatus;
            }
            return u;
          })
        });
        notifySuccess(i18next.t("Status updated to: ") + getStatusLabel(newStatus));
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  };
  const onSortChanged = (e) => {
    if (props.isAdmin) {
      const OrderByValue = e.detail.orderBy;
      const OrderDirValue = e.detail.orderDir;
      setGridState(__spreadProps(__spreadValues({}, gridState), {
        orderBy: OrderByValue,
        orderDir: OrderDirValue
      }));
    }
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    if (props.isAdmin) {
      const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
      });
      setGridState(newGridFilter);
    }
  };
  const [gridState, setGridState] = useQueryGridState(
    {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS,
      pageSize: 20,
      orderBy: "Period",
      orderDir: "desc"
    },
    "ugs"
  );
  const periodsDataSource = useMemo(() => generateMonthlyPeriods(), []);
  const columns = (props.isAdmin ? [
    {
      field: ComplianceUploadFieldNames.ClientId,
      columnClass: "w-16",
      label: "ID",
      sortable: props.isAdmin,
      filterable: props.isAdmin,
      filterDescriptor: { type: "string" },
      columnType: "string"
    },
    {
      field: ComplianceUploadFieldNames.ClientName,
      label: "Client Name",
      sortable: props.isAdmin,
      filterable: props.isAdmin,
      filterDescriptor: { type: "string" },
      columnType: "string"
    }
  ] : []).concat([
    {
      field: ComplianceUploadFieldNames.Period,
      label: i18next.t("Period"),
      filterable: props.isAdmin,
      sortable: props.isAdmin,
      filterDescriptor: { type: "select", multiSelect: true, dataSource: periodsDataSource },
      columnType: "enum"
    },
    {
      field: ComplianceUploadFieldNames.Type,
      label: i18next.t("Type"),
      filterable: props.isAdmin,
      sortable: props.isAdmin,
      filterDescriptor: {
        type: "select",
        multiSelect: false,
        dataSource: getTypeFilterDataSource()
      },
      columnType: "enum"
    },
    {
      field: ComplianceUploadFieldNames.ServiceCountry,
      columnClass: "w-16",
      label: i18next.t("Country"),
      filterable: props.isAdmin,
      sortable: props.isAdmin,
      filterDescriptor: {
        type: "select",
        dataSource: props.serviceCountries.map((sc) => ({ label: sc.Name, value: sc.Code })),
        searchable: true,
        searchProps: { type: "includes" }
      },
      columnType: "enum"
    },
    {
      field: ComplianceUploadFieldNames.Status,
      label: i18next.t("Status"),
      filterable: props.isAdmin,
      sortable: props.isAdmin,
      filterDescriptor: {
        type: "select",
        dataSource: Statuses
      },
      columnType: "enum"
    },
    {
      field: ComplianceUploadFieldNames.Actions,
      label: ""
    }
  ]);
  const vm = {
    columns,
    data: (_a = props.searchResult) == null ? void 0 : _a.uploads,
    // eslint-disable-next-line complexity
    cellTemplate: (index, field) => {
      var _a2;
      const item = (_a2 = props.searchResult) == null ? void 0 : _a2.uploads[index];
      if (field === ComplianceUploadFieldNames.ClientId) {
        return item.ClientId.toString();
      } else if (field === ComplianceUploadFieldNames.ClientName) {
        return item.ClientName;
      } else if (field === ComplianceUploadFieldNames.Period) {
        return moment(item.Period, "MMM-YYYY", "en").locale(moment.locale()).format("MMM-YYYY");
      } else if (field === ComplianceUploadFieldNames.Type) {
        return getType(item.Type);
      } else if (field === ComplianceUploadFieldNames.ServiceCountry) {
        return item.CountryCode === "EL" ? "GR" : item.CountryCode;
      } else if (field === ComplianceUploadFieldNames.Status) {
        const statusLabel = getStatusLabel(item.Status);
        return props.isAdmin && statusLabel === "Error" && item.ErrorReason !== void 0 ? html`<span title=${`${item.ErrorReason} (${item.ErrorMessage})`}>${statusLabel}</span>` : statusLabel;
      } else if (field === ComplianceUploadFieldNames.Actions) {
        return html`
					<div class="flex">
						${!isFinalizedStatusLike(item.Status) && (!props.isAdmin || props.permissionManager.hasPermission("Compliance_Upload_Modify")) ? PrimaryButton({
          class: "max-w-fit m-1",
          text: i18next.t("Update"),
          size: "small",
          onClick: () => props.openUploadModal(item.Id, false),
          disabled: item.IsOverdue !== void 0 && item.IgnoreOverdue !== void 0 && item.IsOverdue && !item.IgnoreOverdue
        }) : ""}
						${!props.isAdmin && item.IsOverdue !== void 0 && item.IgnoreOverdue !== void 0 && item.IsOverdue && !item.IgnoreOverdue ? html` <dc-tooltip .label=${i18next.t("Upload is forbidden for overdue periods. Please contact your account manager.")}></dc-tooltip>` : ""}
						${item.Status === "InsufficientCredit" && (!props.isAdmin || props.permissionManager.hasPermission("Compliance_Upload_Modify")) ? PrimaryButton({ class: "max-w-fit m-1", text: i18next.t("Reconfirm Upload"), size: "small", onClick: () => reconfirm(item.Id) }) : ""}
						${props.isAdmin && item.Status === "InsufficientCredit" && props.permissionManager.hasPermission("Compliance_Upload_Modify") ? PrimaryButton({ class: "max-w-fit m-1", text: i18next.t("Cancel"), size: "small", onClick: () => cancel(item.Id) }) : ""}
						${props.isAdmin && item.CompanyConfigSnapshot !== void 0 ? PrimaryButton({ class: "max-w-fit m-1", text: i18next.t("View"), size: "small", onClick: () => props.openUploadModal(item.Id, true) }) : ""}
						${props.isAdmin && isFinalizedStatusLike(item.Status) ? PrimaryButton({ class: "max-w-fit m-1", text: "TXT", size: "small", onClick: () => props.generateTextFile(item.Id) }) : ""}
						${props.isAdmin && item.Status === "SentToReturnGeneration" && props.permissionManager.hasPermission("Compliance_Upload_Modify") ? html`
									${PrimaryButton({ class: "max-w-fit m-1", text: i18next.t("Resend"), size: "small", onClick: () => props.resendNotification(item.Id) })}
									${PrimaryButton({ class: "max-w-fit m-1", text: i18next.t("Regenerate"), size: "small", onClick: () => props.regenerateDataVat(item.Id) })}
							  ` : ""}
						${props.isAdmin && item.Status === "Error" && props.permissionManager.hasPermission("Compliance_Upload_Modify") ? html`
									${item.ErrorReason === "UnableToSentToReturnGeneration" ? PrimaryButton({ class: "max-w-fit m-1", text: i18next.t("Resend"), size: "small", onClick: () => props.resendNotification(item.Id) }) : ""}
									${PrimaryButton({ class: "max-w-fit m-1", text: i18next.t("Regenerate"), size: "small", onClick: () => props.regenerateDataVat(item.Id) })}
							  ` : ""}
						${props.isAdmin && item.Type === "IOSS" && item.IsOverdue && props.permissionManager.hasPermission("Compliance_Upload_Modify") ? PrimaryButton({
          class: "max-w-fit m-1",
          text: i18next.t(item.IgnoreOverdue ? "Disable" : "Enable"),
          size: "small",
          onClick: () => props.changeIgnoreOverdue(item.Id, !item.IgnoreOverdue)
        }) : ""}
					</div>
				`;
      } else {
        return "";
      }
    },
    appliedFilters: gridState.appliedFilters,
    paging: {
      pageable: true,
      pageIndex: gridState.pageIndex,
      pageSize: gridState.pageSize,
      buttonCount: 10,
      pageSizes: [20, 50, 100],
      itemCount: (_b = props.searchResult) == null ? void 0 : _b.total
    }
  };
  const htmlTemplate = () => html` <dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table> `;
  return { htmlTemplate, gridState, setGridState };
};
