import "./dc-toggle.scss";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { component } from "haunted";
const DEFAULTS = {
  state: "None",
  labelNo: i18next.t("No"),
  labelYes: i18next.t("Yes"),
  readonly: false,
  tooltip: "",
  customClass: ""
};
export class ChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, cancelable: true });
  }
}
export const Component = (host) => {
  const props = {
    state: host.state !== void 0 ? host.state : DEFAULTS.state,
    labelNo: host.labelNo !== void 0 ? host.labelNo : DEFAULTS.labelNo,
    labelYes: host.labelYes !== void 0 ? host.labelYes : DEFAULTS.labelYes,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    tooltip: host.tooltip !== void 0 ? host.tooltip : DEFAULTS.tooltip,
    label: host.label,
    validationMessage: host.validationMessage,
    customClass: host.customClass !== void 0 ? host.customClass : DEFAULTS.customClass
  };
  const onClick = (newState) => {
    if (props.readonly) {
      return;
    }
    if (host.dispatchEvent(
      new ChangeEvent({
        state: newState
      })
    )) {
      setCurrentState(newState);
    }
  };
  const [currentState, setCurrentState] = useState(props.state);
  useEffect(() => {
    setCurrentState(props.state);
  }, [props.state]);
  return html`
		<div class="${props.customClass}">
			${props.label !== void 0 ? html`<label class="form-label">${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""}</label>` : ""}
			<div class="wrapper ${props.validationMessage ? "invalid" : ""}">
				<div
					class="no ${currentState === "No" ? "selected" : ""} ${props.readonly ? "readonly" : ""}"
					@click=${() => {
    if (!props.readonly) {
      onClick("No");
    }
  }}
				>
					${props.labelNo}
				</div>
				<div
					class="yes ${currentState === "Yes" ? "selected" : ""} ${props.readonly ? "readonly" : ""}"
					@click=${() => {
    if (!props.readonly) {
      onClick("Yes");
    }
  }}
				>
					${props.labelYes}
				</div>
			</div>
			${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
		</div>
	`;
};
customElements.define(
  "dc-toggle",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
