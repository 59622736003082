import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
export function Contact() {
  return html`<div class="py-2 mx-auto max-w-5xl">
		<h1 class="text-2xl mt-12 mb-8 font-bold">${i18next.t("Contact")}</h1>
		<p class="mb-6 text-brand-primary font-bold">
			${unsafeHTML(
    i18next.t(
      "contacts_message",
      "Specific questions: please write to {{- link_start}}{{- contactEmail}}{{- link_end}} or call our helpline on the numbers below (between 9:00 and 17:00 Central European Time):",
      {
        link_start: `<a class="underline" href="mailto:${ClientContext.ContactEmailAddress}">`,
        contactEmail: ClientContext.ContactEmailAddress,
        link_end: "</a>"
      }
    )
  )}
		</p>
		<p class="mb-2 text-gray-600">${i18next.t("UK")}: +44 20 8050 3097</p>
		<p class="mb-2 text-gray-600">${i18next.t("Spain")}: +34 919 49 6077</p>
		<p class="mb-2 text-gray-600">${i18next.t("Hungary")}: +36 1 700 9471</p>
		<p class="mt-6 text-brand-primary font-bold">${i18next.t("We look forward to helping you.")}</p>
	</div>`;
}
