import { File } from "./File";
import { AmazonFile } from "./AmazonFile";
import { AmazonSaftFile } from "./AmazonSaftFile";
import { CustomSaftFile } from "./CustomSaftFile";
import { IossFile } from "./IossFile";
import { MpTemplateFile } from "./MpTemplateFile";
import { SapExcelFile } from "./SapExcelFile";
import { SapTextFile } from "./SapTextFile";
export class FileFactory {
  static create($type) {
    if ($type === "V1.ComplianceUploads.Files.File") {
      const result = new File();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.AmazonFile") {
      const result = new AmazonFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.AmazonSaftFile") {
      const result = new AmazonSaftFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.CustomSaftFile") {
      const result = new CustomSaftFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.IossFile") {
      const result = new IossFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.MpTemplateFile") {
      const result = new MpTemplateFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.SapExcelFile") {
      const result = new SapExcelFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.SapTextFile") {
      const result = new SapTextFile();
      return result;
    }
    throw new Error($type + " not found");
  }
}
