var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { formatDate, getDay, interpretYearOneAsUndefined } from "GlobalShared/helpers/dateHelper";
import { notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { COMPLIANCE_SUBSCRIPTION_STATUS_LABELS } from "GlobalShared/models/ServicesModels";
import i18next from "i18next";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { useCommentModal } from "../reg-modals/comment-modal";
import { getStatusLabel } from "./services-common";
import { useConfirmModal } from "../modals/commonModals";
import { useSubCancellationModal } from "./sub-cancellation-modal";
import { useDateEditorModal } from "GlobalShared/components/services/date-editor-modal";
import { isValidOssTransitionForRegistrationType } from "GlobalShared/helpers/servicesHelper";
import { useOSSModal } from "../reg-modals/oss-modal";
import { useDeregistrationModal } from "../reg-modals/deregistration-modal";
import { downloadPDFIcon } from "GlobalShared/templates/commons";
import { canChangeToSentToTa } from "./service-helpers";
import { usePaymentReferenceModal } from "./payment-reference-modal";
import classNames from "classnames";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
export const useOssService = (props) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _Y, _Z, __, _$;
  const getValidNextOssTransition = (from) => {
    var _a2;
    return (_a2 = validOSSTransitions == null ? void 0 : validOSSTransitions.reduce((aggr, curr) => {
      var _a3;
      if (curr.From === from && isValidOssTransitionForRegistrationType(curr.Type, (_a3 = props.companyServices.GlobalServices) == null ? void 0 : _a3.OssService.RegistrationType)) {
        return aggr.concat(curr.To);
      } else {
        return aggr;
      }
    }, [])) != null ? _a2 : [];
  };
  const changeOssSubscriptionStartDate = (selectedDate) => __async(void 0, null, function* () {
    var _a2;
    if (getDay(selectedDate) > getDay((_a2 = props.companyServices.GlobalServices) == null ? void 0 : _a2.OssService.ComplianceValidTo)) {
      notifyWarning(i18next.t("Service start date cannot be after the service expiration date."));
      return;
    }
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a3;
        yield props.callbackHandler.changeSubscriptionStartDate(props.companyId, (_a3 = props.companyServices.GlobalServices) == null ? void 0 : _a3.OssService.ComplianceSubscriptionId, selectedDate);
        notifySuccess(i18next.t("Saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const changeOssSubscriptionEndDate = (selectedDate) => __async(void 0, null, function* () {
    var _a2;
    if (getDay(selectedDate) < getDay((_a2 = props.companyServices.GlobalServices) == null ? void 0 : _a2.OssService.ValidFrom)) {
      notifyWarning(i18next.t("Service expiration date cannot be before the service start date."));
      return;
    }
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a3;
        yield props.callbackHandler.changeSubscriptionEndDate(props.companyId, (_a3 = props.companyServices.GlobalServices) == null ? void 0 : _a3.OssService.ComplianceSubscriptionId, selectedDate);
        notifySuccess(i18next.t("Saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const changeOssRegistrationStartDate = (selectedDate) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a2;
        yield props.callbackHandler.changeOssRegistrationStartDate(props.companyId, (_a2 = props.companyServices.GlobalServices) == null ? void 0 : _a2.OssService.Id, selectedDate);
        notifySuccess(i18next.t("Saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [currentDateEditorModalProps, setCurrentDateEditorModalProps] = useState(void 0);
  const dateEditorModal = useDateEditorModal(currentDateEditorModalProps);
  const [validOSSTransitions, setValidOSSTransitions] = useState([]);
  const [currentCommentModalProps, setCurrentCommentModalProps] = useState(void 0);
  const commentModal = useCommentModal(currentCommentModalProps);
  const confirmModal = useConfirmModal();
  const [currentCancellationModalProps, setCurrentCancellationModalProps] = useState(void 0);
  const subCancellationModal = useSubCancellationModal(currentCancellationModalProps);
  const [currentOSSModalProps, setCurrentOSSModalProps] = useState(void 0);
  const ossModal = useOSSModal(currentOSSModalProps);
  const [currentDeregistrationModalProps, setCurrentDeregistrationModalProps] = useState(void 0);
  const deregistrationModal = useDeregistrationModal(currentDeregistrationModalProps);
  const [currentPaymentReferenceModalProps, setCurrentPaymentReferenceModalProps] = useState(void 0);
  const paymentReferenceModal = usePaymentReferenceModal(currentPaymentReferenceModalProps);
  const closingDateEditorModal = () => {
    setCurrentDateEditorModalProps(void 0);
  };
  useEffect(() => __async(void 0, null, function* () {
    if (props == null ? void 0 : props.isAdmin) {
      setValidOSSTransitions(yield props.callbackHandler.getPossibleOssStatuses());
    }
  }), [props.callbackHandler]);
  const htmlDateEditor = (options) => {
    return props.isAdmin && options.value && props.permissionManager.hasPermission(options.requiredPermission) ? html`<dc-edit-button
					@click=${() => {
      setCurrentDateEditorModalProps(__spreadProps(__spreadValues({}, options), {
        onClosing: closingDateEditorModal
      }));
      dateEditorModal.show();
    }}
			  ></dc-edit-button>` : "";
  };
  const mainTemplate = ((_b = (_a = props.companyServices) == null ? void 0 : _a.GlobalServices) == null ? void 0 : _b.OssService) !== void 0 && (props.showCancelledServices || ((_c = props.companyServices.GlobalServices) == null ? void 0 : _c.OssService.ComplianceSubscriptionStatus) !== "Cancelled") ? html`
					<div
						class="mt-8 shadow border px-6 py-4 xl:mx-auto rounded-xl ${((_d = props.companyServices.GlobalServices) == null ? void 0 : _d.OssService.ComplianceSubscriptionStatus) === "Cancelled" ? "bg-red-200" : ""}"
					>
						<div class="flex justify-between">
							<div class="flex gap-2">
								<div class="font-bold text-lg mt-1 mb-3">${i18next.t("OSS")}</div>
								<div
									class=${classNames("bg-gray-200 border rounded-full flex items-center justify-center h-9 px-4 text-xs uppercase font-semibold", {
    hidden: ((_g = (_f = (_e = props.companyServices) == null ? void 0 : _e.GlobalServices) == null ? void 0 : _f.OssService) == null ? void 0 : _g.IsSubmissionOnly) === void 0
  })}
								>
									${((_i = (_h = props.companyServices) == null ? void 0 : _h.GlobalServices) == null ? void 0 : _i.OssService.IsSubmissionOnly) ? i18next.t("Submission-only") : i18next.t("Full-compliance")}
								</div>
							</div>
							<div>
								${props.companyServices.ClientSource !== "Partner" ? html`${(props == null ? void 0 : props.isAdmin) ? html`<dc-dropdown
													.isStatusLike=${true}
													.buttonText=${getStatusLabel((_j = props.companyServices.GlobalServices) == null ? void 0 : _j.OssService.RegistrationStatus)}
													.selectedValues=${[(_k = props.companyServices.GlobalServices) == null ? void 0 : _k.OssService.RegistrationStatus]}
													.dataSource=${[
    {
      label: getStatusLabel((_l = props.companyServices.GlobalServices) == null ? void 0 : _l.OssService.RegistrationStatus),
      value: (_m = props.companyServices.GlobalServices) == null ? void 0 : _m.OssService.RegistrationStatus
    }
  ].concat(
    getValidNextOssTransition((_n = props.companyServices.GlobalServices) == null ? void 0 : _n.OssService.RegistrationStatus).map((status) => ({
      label: getStatusLabel(status),
      value: status
    }))
  )}
													.readonly=${!props.permissionManager.hasPermission("OSS_Modify")}
													@change=${(e) => {
    var _a2, _b2, _c2, _d2;
    const newOSSStatus = e.detail.selectedValue;
    if (((_a2 = props.companyServices.GlobalServices) == null ? void 0 : _a2.OssService.RegistrationStatus) === "DocumentsSigned" && newOSSStatus === "WaitingForSignature") {
      setCurrentCommentModalProps({
        save: (comment) => __async(void 0, null, function* () {
          var _a3, _b3;
          yield props.callbackHandler.changeOssRegistrationStatus(
            (_a3 = props.companyServices.GlobalServices) == null ? void 0 : _a3.OssService.Id,
            (_b3 = props.companyServices.GlobalServices) == null ? void 0 : _b3.OssService.RegistrationStatus,
            newOSSStatus,
            comment
          );
        })
      });
      commentModal.show();
    } else if (["Pending", "DocumentsSigned"].includes(props.companyServices.GlobalServices.OssService.RegistrationStatus) && newOSSStatus === "SentToTA") {
      if (canChangeToSentToTa(props.companyServices, "OSS_Modify_Advanced", props.permissionManager)) {
        props.callbackHandler.changeOssRegistrationStatus(
          props.companyServices.GlobalServices.OssService.Id,
          props.companyServices.GlobalServices.OssService.RegistrationStatus,
          newOSSStatus
        );
      } else {
        notifyWarning(i18next.t("Client acceptance or AML is not valid."));
      }
    } else if (props.companyServices.GlobalServices.OssService.RegistrationStatus === "SentToTA" && newOSSStatus === "RegistrationCompleted") {
      setCurrentOSSModalProps({
        ossCertificate: props.companyServices.GlobalServices.OssService.OSSCertificate,
        paymentReference: props.companyServices.GlobalServices.OssService.PaymentReference,
        ossCountryCode: (_b2 = props.companyServices.GlobalServices.OssService.Country) == null ? void 0 : _b2.Code,
        save: (ossCertificate, paymentReference) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeOssRegistrationStatus(
            props.companyServices.GlobalServices.OssService.Id,
            props.companyServices.GlobalServices.OssService.RegistrationStatus,
            newOSSStatus,
            void 0,
            ossCertificate,
            void 0,
            paymentReference
          );
        }),
        uploadOSSCertificate: (file) => {
          return props.callbackHandler.uploadOssDocument(props.companyServices.GlobalServices.OssService.Id, file);
        }
      });
      ossModal.show();
    } else if (props.companyServices.GlobalServices.OssService.RegistrationStatus === "DeregistrationInProgress" && newOSSStatus === "Deregistered") {
      setCurrentDeregistrationModalProps({
        change: {},
        save: (change) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeOssRegistrationStatus(
            props.companyServices.GlobalServices.OssService.Id,
            props.companyServices.GlobalServices.OssService.RegistrationStatus,
            newOSSStatus,
            void 0,
            void 0,
            change
          );
        }),
        uploadDocument: (file) => {
          return props.callbackHandler.uploadOssDocument(props.companyServices.GlobalServices.OssService.Id, file);
        }
      });
      deregistrationModal.show();
    } else {
      props.callbackHandler.changeOssRegistrationStatus(
        (_c2 = props.companyServices.GlobalServices) == null ? void 0 : _c2.OssService.Id,
        (_d2 = props.companyServices.GlobalServices) == null ? void 0 : _d2.OssService.RegistrationStatus,
        newOSSStatus
      );
    }
  }}
											  ></dc-dropdown>` : html` <div>${getStatusLabel(props.companyServices.GlobalServices.OssService.RegistrationStatus)}</div> `}` : ""}
							</div>
						</div>
						<div class="border-b border-gray-300 pb-4">
							<div class="flex items-center text-sm">
								<span>${i18next.t("Service start date")}: ${formatDate((_o = props.companyServices.GlobalServices) == null ? void 0 : _o.OssService.ValidFrom)}</span>
								${htmlDateEditor({
    title: "Change Service start date",
    fieldName: "Service start date",
    value: (_p = props.companyServices.GlobalServices) == null ? void 0 : _p.OssService.ValidFrom,
    saveDate: changeOssSubscriptionStartDate,
    requiredPermission: "Company_Subscription_Dates_Modify",
    max: (_q = props.companyServices.GlobalServices) == null ? void 0 : _q.OssService.ComplianceValidTo
  })}
								&nbsp;|&nbsp;
								<span
									>${i18next.t("Service expiration date")}:
									${((_r = props.companyServices.GlobalServices) == null ? void 0 : _r.OssService.ComplianceSubscriptionStatus) === "Pending" ? "-" : formatDate((_s = props.companyServices.GlobalServices) == null ? void 0 : _s.OssService.ComplianceValidTo)}</span
								>
								${((_t = props.companyServices.GlobalServices) == null ? void 0 : _t.OssService.ComplianceSubscriptionStatus) !== "Pending" ? htmlDateEditor({
    title: "Change Service expiration date",
    fieldName: "Service expiration date",
    value: (_u = props.companyServices.GlobalServices) == null ? void 0 : _u.OssService.ComplianceValidTo,
    saveDate: changeOssSubscriptionEndDate,
    requiredPermission: "Company_Subscription_Dates_Modify",
    min: (_v = props.companyServices.GlobalServices) == null ? void 0 : _v.OssService.ValidFrom
  }) : ""}
							</div>
							<div class="flex items-center text-sm">
								<span
									>${((_x = (_w = props.companyServices.GlobalServices) == null ? void 0 : _w.OssService) == null ? void 0 : _x.RegistrationType) === "TakeOver" ? i18next.t("Start date of representation by Tax Desk") : i18next.t("Effective date of registration")}:
									${formatDate((_z = (_y = props.companyServices.GlobalServices) == null ? void 0 : _y.OssService) == null ? void 0 : _z.RegistrationStartDate)}</span
								>
								${htmlDateEditor({
    title: "Change Start date",
    fieldName: "Start date",
    value: (_A = props.companyServices.GlobalServices) == null ? void 0 : _A.OssService.RegistrationStartDate,
    saveDate: changeOssRegistrationStartDate,
    requiredPermission: "OSS_Modify_StartDate"
  })}
							</div>
						</div>
						<div class="justify-between border-b border-gray-300 items-center py-4">
							<div class="flex space-x-2 justify-between text-sm">
								<div class="flex space-x-2 p-2">
									<div>
										${i18next.t("Compliance status")}: ${COMPLIANCE_SUBSCRIPTION_STATUS_LABELS[(_B = props.companyServices.GlobalServices) == null ? void 0 : _B.OssService.ComplianceSubscriptionStatus]}
										${interpretYearOneAsUndefined((_C = props.companyServices.GlobalServices) == null ? void 0 : _C.OssService.ComplianceSubscriptionCancellationDate) ? html`(${formatDate((_D = props.companyServices.GlobalServices) == null ? void 0 : _D.OssService.ComplianceSubscriptionCancellationDate)})` : ""}
									</div>
								</div>
								<div>
									${props.isAdmin && props.permissionManager.hasPermission("OSS_Modify") && ((_E = props.companyServices.GlobalServices) == null ? void 0 : _E.OssService.ComplianceSubscriptionStatus) === "Active" ? html`
												${SecondaryButton({
    class: "max-w-fit",
    text: i18next.t("Cancel Subscription"),
    onClick: () => __async(void 0, null, function* () {
      return () => {
        var _a2, _b2, _c2;
        setCurrentCancellationModalProps({
          contractFrom: (_a2 = props.companyServices.GlobalServices) == null ? void 0 : _a2.OssService.ValidFrom,
          contractTo: (_b2 = props.companyServices.GlobalServices) == null ? void 0 : _b2.OssService.ComplianceValidTo,
          subscriptionId: (_c2 = props.companyServices.GlobalServices) == null ? void 0 : _c2.OssService.ComplianceSubscriptionId,
          cancelSubscription: props.callbackHandler.cancelSubscription
        });
        subCancellationModal.show();
      };
    })
  })}
										  ` : ""}
								</div>
							</div>
						</div>
						${((_H = (_G = (_F = props.companyServices.GlobalServices) == null ? void 0 : _F.OssService) == null ? void 0 : _G.OSSCertificate) == null ? void 0 : _H.CertificateReferenceId) || ((_K = (_J = (_I = props.companyServices.GlobalServices) == null ? void 0 : _I.OssService) == null ? void 0 : _J.OSSCertificate) == null ? void 0 : _K.RegistrationNumber) || ((_N = (_M = (_L = props.companyServices.GlobalServices) == null ? void 0 : _L.OssService) == null ? void 0 : _M.OSSCertificate) == null ? void 0 : _N.RegistrationDate) ? html`<div class="flex flex-col gap-2 text-sm mt-4">
									<div class="flex space-x-2">
										<div>${i18next.t("OSS Number")}:</div>
										<div>
											${(_Q = (_P = (_O = props.companyServices.GlobalServices) == null ? void 0 : _O.OssService) == null ? void 0 : _P.OSSCertificate) == null ? void 0 : _Q.RegistrationNumber}
											(${formatDate((_T = (_S = (_R = props.companyServices.GlobalServices) == null ? void 0 : _R.OssService) == null ? void 0 : _S.OSSCertificate) == null ? void 0 : _T.RegistrationDate)})
										</div>
									</div>
									${((_V = (_U = props.companyServices.GlobalServices) == null ? void 0 : _U.OssService.Country) == null ? void 0 : _V.Code) === "DE" ? html` <div class="flex space-x-2">
												<div>${i18next.t("Payment reference")}:</div>
												<div>${(_Y = (_X = (_W = props.companyServices.GlobalServices) == null ? void 0 : _W.OssService) == null ? void 0 : _X.PaymentReference) != null ? _Y : "-"}</div>
												${props.isAdmin && props.permissionManager.hasPermission("OSS_Modify") ? html`<dc-edit-button
															@click=${() => __async(void 0, null, function* () {
    var _a2, _b2;
    setCurrentPaymentReferenceModalProps({
      paymentReference: (_b2 = (_a2 = props.companyServices.GlobalServices) == null ? void 0 : _a2.OssService) == null ? void 0 : _b2.PaymentReference,
      save: (paymentReference) => __async(void 0, null, function* () {
        var _a3;
        yield props.callbackHandler.changeOssPaymentReference(
          props.companyId,
          (_a3 = props.companyServices.GlobalServices) == null ? void 0 : _a3.OssService.Id,
          paymentReference
        );
      })
    });
    paymentReferenceModal.show();
  })}
													  >
													  </dc-edit-button>` : ""}
										  </div>` : ""}
									<div class="flex space-x-2">${i18next.t("Country of registration")}: ${(__ = (_Z = props.companyServices.GlobalServices) == null ? void 0 : _Z.OssService.Country) == null ? void 0 : __.Code}</div>
									${props.companyServices.GlobalServices.OssService.DeregistrationInfo ? html`
												<div class="flex space-x-2">
													<div>${i18next.t("Deregistered")}: (${formatDate(props.companyServices.GlobalServices.OssService.DeregistrationInfo.DeregisteredAt)})</div>
													${((_$ = props.companyServices.GlobalServices.OssService.DeregistrationInfo) == null ? void 0 : _$.DocumentId) ? html` <div
																class="cursor-pointer"
																@click=${() => __async(void 0, null, function* () {
    yield props.callbackHandler.downloadOssDeregistrationDocument(props.companyServices.GlobalServices.OssService.Id);
  })}
														  >
																${downloadPDFIcon()}
														  </div>` : ""}
												</div>
										  ` : ""}
							  </div> ` : ""}
					</div>
			  ` : "";
  const htmlModals = html`${commentModal.template()} ${deregistrationModal.template} ${ossModal.template} ${confirmModal.mainTemplate()} ${subCancellationModal.template()}
	${dateEditorModal.template()} ${paymentReferenceModal.template()}`;
  return { mainTemplate, htmlModals };
};
