var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useReturnLibrary } from "GlobalShared/components/return-library/return-library";
import {
  ReturnLibraryFieldNames,
  mapSearchResultItemToVM,
  mapSubmissionPeriodFilesToVM
} from "GlobalShared/components/return-library/SubmissionModels";
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
import { useCurrencies, useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { SubmissionPeriods as SubmissionClient } from "WebServices/APIs/self/clients/SubmissionPeriods";
import { DateRange } from "WebServices/APIs/self/models/V1/Common/DateTime";
import { Approved, Due, Paid, PaidByClient, Refused, SubmittedByClient } from "WebServices/APIs/self/models/V1/Submission";
import { Field, Filter, Pager, SearchRequest } from "WebServices/APIs/self/models/V1/Submission/Search";
import { FieldOrder } from "WebServices/APIs/self/models/V1/Submission/Search/Field";
import { currentLocale } from "../../globals";
import { html } from "lit-html";
import i18next from "i18next";
import { getlastPeriodFilters } from "GlobalShared/helpers/periodHelper";
import { Company as CompanyClient } from "WebServices/APIs/self/clients";
import { OssComplianceSubscription, VatComplianceSubscription } from "WebServices/APIs/self/models/V1/CompanyInfo/Subscriptions";
export function AdminReturnLibrary() {
  const getTargetSubmissionStatus = (from, to, comment, attachmentIds) => {
    if (from === "ReturnAvailable" && to === "ApprovedByClient" || from === "SubmittedByClient" && to === "ApprovedByClient" || from === "RefusedByClient" && to === "ApprovedByClient") {
      return new Approved();
    } else if (from === "ReturnAvailable" && to === "RefusedByClient") {
      return new Refused({ Comment: comment, AttachmentReferences: attachmentIds });
    } else if (from === "ApprovedByClient" && to === "SubmittedByClient") {
      return new SubmittedByClient();
    }
    return void 0;
  };
  const uploadSubmissionProof = (submissionPeriodId, files) => __async(this, null, function* () {
    yield submissionClient.uploadSubmissionProof({
      submissionPeriodId,
      file: files[0]
    });
  });
  const uploadPaymentProof = (submissionPeriodId, file) => __async(this, null, function* () {
    yield submissionClient.uploadPaymentProof({
      submissionPeriodId,
      file
    });
  });
  const uploadNotToBeSubmittedProof = (_submissionPeriodId, _file) => __async(this, null, function* () {
  });
  const downloadDocument = (submissionPeriodId, documentType, fileReference) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield submissionClient.downloadDocument({
          type: documentType,
          submissionPeriodId,
          documentId: fileReference
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const changeStatus = (submissionPeriodId, oldStatus, newStatus, comment, attachmentIds, _isRevision, _returnGeneratedModel) => __async(this, null, function* () {
    const targetStatus = getTargetSubmissionStatus(oldStatus, newStatus, comment, attachmentIds);
    yield submissionClient.changeStatus({
      submissionPeriodId,
      body: targetStatus
    });
  });
  const changeRevision = (_submissionPeriodId, _isRevision) => __async(this, null, function* () {
  });
  const changePaymentStatus = (submissionPeriodId, newStatus) => __async(this, null, function* () {
    const targetStatus = getTargetPaymentStatus(newStatus);
    yield submissionClient.changePaymentStatus({
      submissionPeriodId,
      body: targetStatus
    });
    if (newStatus === "Paid" || newStatus === "PaidByClient") {
      setShowAcknowledgeModal(true);
    }
  });
  const getTargetPaymentStatus = (to) => {
    if (to === "Due") {
      return new Due();
    } else if (to === "Paid") {
      return new Paid();
    } else if (to === "PaidByClient") {
      return new PaidByClient();
    }
    return void 0;
  };
  const getFilter = (searchRequest, showLastPeriodsOnly) => {
    const clientFilter = new Filter();
    if (searchRequest.paymentProofFilter !== "All") {
      clientFilter.PaymentProofUploaded = searchRequest.paymentProofFilter === "Exists";
    }
    if (searchRequest.gridState.appliedFilters.length > 0) {
      searchRequest.gridState.appliedFilters.forEach((filter) => {
        if (filter.filterProps.selectedValues) {
          if (filter.field === ReturnLibraryFieldNames.Status) {
            clientFilter.Statuses = filter.filterProps.selectedValues;
          } else if (filter.field === ReturnLibraryFieldNames.TaxReturnType) {
            clientFilter.TaxReturnTypes = filter.filterProps.selectedValues;
          } else if (filter.field === ReturnLibraryFieldNames.PeriodStartDate) {
            clientFilter.Periods = filter.filterProps.selectedValues;
          } else if (filter.field === ReturnLibraryFieldNames.PaymentProofUploaded) {
            clientFilter.PaymentProofUploaded = filter.filterProps.selectedValues === "Exists";
          }
        }
        if (filter.filterProps.min || filter.filterProps.max) {
          if (filter.field === ReturnLibraryFieldNames.ClientApprovalDueDate) {
            clientFilter.ClientApprovalDueDate = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          } else if (filter.field === ReturnLibraryFieldNames.SubmissionDueDate) {
            clientFilter.SubmissionDueDate = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          } else if (filter.field === ReturnLibraryFieldNames.ReturnGenerationDueDate) {
            clientFilter.ReturnGenerationDueDate = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          } else if (filter.field === ReturnLibraryFieldNames.PaymentDueDate) {
            clientFilter.PaymentDueDate = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          }
        }
        if (filter.field === ReturnLibraryFieldNames.ServiceCountry) {
          clientFilter.ServiceCountryCode = filter.filterProps.selectedValues;
        }
      });
    }
    if (searchRequest.periodSubmissionIdsForFilter) {
      clientFilter.SubmissionPeriodIds = searchRequest.periodSubmissionIdsForFilter;
    }
    if (showLastPeriodsOnly) {
      clientFilter.Periods = getlastPeriodFilters();
    }
    return clientFilter;
  };
  const searchSubmissions = (searchRequest, _onlyAssignedToMe, _assigneeFragment, showLastPeriodsOnly) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield submissionClient.searchSubmissions({
          body: new SearchRequest({
            Filter: getFilter(searchRequest, showLastPeriodsOnly),
            Pager: new Pager({
              Skip: searchRequest.gridState.pageIndex * searchRequest.gridState.pageSize,
              Top: searchRequest.gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: searchRequest.gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: searchRequest.gridState.orderBy
                })
              ]
            })
          })
        });
        const vm = {
          submissions: response.data.SubmissionPeriods.map(mapSearchResultItemToVM),
          total: response.data.Count
        };
        return vm;
      }),
      (error) => {
        throw error;
      }
    );
  });
  const exportSubmissions = (_searchRequest) => __async(this, null, function* () {
  });
  const exportReturns = (_searchRequest) => __async(this, null, function* () {
  });
  const proofBatchUpload = (_files) => __async(this, null, function* () {
  });
  const getSubmissionPeriodFiles = (submissionPeriodId) => __async(this, null, function* () {
    const response = yield submissionClient.getSubmissionPeriodFiles({
      submissionPeriodId
    });
    return mapSubmissionPeriodFilesToVM(response.data);
  });
  const uploadRejectionAttachments = (submissionPeriodId, files) => __async(this, null, function* () {
    const response = yield submissionClient.uploadRejectionAttachments({
      submissionPeriodId,
      files
    });
    return response.data;
  });
  const uploadReturnDocuments = (submissionPeriodId, files, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        return submissionClient.uploadReturnDocument({
          submissionPeriodId,
          file: files[0]
        });
      }),
      (error) => {
        notifyError(error);
      },
      void 0,
      loader
    );
  });
  const confirmReturnDocument = (submissionPeriodId, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        return submissionClient.confirmReturnDocument({
          submissionPeriodId
        });
      }),
      (error) => {
        notifyError(error);
      },
      void 0,
      loader
    );
  });
  const getCompanyDetail = (locale) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetail = yield companyClient.getCompanyDetail({
          locale
        });
        return companyDetail.data;
      }),
      (error) => {
        notifyError(error);
      },
      void 0
    );
  });
  const updateIgnoreWarnings = (_submissionPeriodId, _ignoreWarnings) => __async(this, null, function* () {
  });
  const availableCurrencies = useCurrencies();
  const submissionClient = useClient(SubmissionClient);
  const companyClient = useClient(CompanyClient);
  const worldCountries = useWorldCountries(currentLocale);
  const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);
  const [hasSubmissionOnlySubscription, setHasSubmissionOnlySubscription] = useState(false);
  useEffect(() => __async(this, null, function* () {
    const companyDetailResult = yield getCompanyDetail(currentLocale);
    setHasSubmissionOnlySubscription(companyDetailResult.Subscriptions.some((s) => (s instanceof VatComplianceSubscription || s instanceof OssComplianceSubscription) && s.IsSubmissionOnly));
  }), []);
  const acknowledgeModal = () => {
    return html`
			<div>
				<dc-modal
					.visible=${showAcknowledgeModal}
					@close=${() => setShowAcknowledgeModal(false)}
					.header=${i18next.t("Acknowledgement")}
					.content=${html` <div class="mt-4 space-y-4">
						<div class="italic">${i18next.t("submissionperiod-paymentstatuschange-acknowledge")}</div>
						<div>
							<button class="btn btn-primary block" @click=${() => setShowAcknowledgeModal(false)}>${i18next.t("Ok")}</button>
						</div>
					</div>`}
				>
				</dc-modal>
			</div>
		`;
  };
  const returnLibraryHook = useReturnLibrary({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    worldCountries,
    availableCurrencies,
    hasSubmissionOnlySubscription,
    searchSubmissions,
    exportSubmissions,
    exportReturns,
    getSubmissionPeriodFiles,
    uploadSubmissionProof,
    uploadPaymentProof,
    uploadReturnDocuments,
    downloadDocument,
    changeStatus,
    changeRevision,
    changePaymentStatus,
    uploadRejectionAttachments,
    uploadNotToBeSubmittedProof,
    proofBatchUpload,
    confirmReturnDocument,
    updateIgnoreWarnings
  });
  return html`${returnLibraryHook.mainTemplate}${acknowledgeModal()}`;
}
