import { html } from "lit-html";
import { uploadFileToQuestion } from "GlobalShared/helpers/questionnaireHelper";
import i18next from "i18next";
import { templateTitle } from "GlobalShared/templates/commons";
export const observedAttributes = ["q11e", "worldCountries", "euCountries"];
export const useShadowDOM = false;
export const name = "ww-q11e-business-details";
const DEFAULTS = {
  worldCountries: [],
  euCountries: []
};
export const Component = (host) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R;
  const props = {
    q11e: host.q11e,
    worldCountries: host.worldCountries ? host.worldCountries : DEFAULTS.worldCountries,
    euCountries: host.euCountries ? host.euCountries : DEFAULTS.euCountries
  };
  return html`
		<span>
			<div class="my-8">${templateTitle(i18next.t("Business Details"))}</div>
			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<dc-input
					class=${!((_a = props.q11e) == null ? void 0 : _a.isIndividual()) ? "" : "hidden"}
					.readonly=${true}
					.label=${i18next.t("Company Name")}
					.value=${!((_b = props.q11e) == null ? void 0 : _b.isIndividual()) ? (_d = (_c = props.q11e) == null ? void 0 : _c.getCompanyDetail()) == null ? void 0 : _d.CompanyName : ""}
				></dc-input>
			</div>
			<div>
				<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
					<dc-input
						class=${((_e = props.q11e) == null ? void 0 : _e.isIndividual()) ? "" : "hidden"}
						.readonly=${true}
						.label=${i18next.t("First Name of Proprietor")}
						.value=${((_f = props.q11e) == null ? void 0 : _f.isIndividual()) ? (_g = props.q11e) == null ? void 0 : _g.getIndividualDetail().Name.FirstName : ""}
					></dc-input
					><dc-input
						class=${((_h = props.q11e) == null ? void 0 : _h.isIndividual()) ? "" : "hidden"}
						.readonly=${true}
						.label=${i18next.t("Last Name of Proprietor")}
						.value=${((_i = props.q11e) == null ? void 0 : _i.isIndividual()) ? (_j = props.q11e) == null ? void 0 : _j.getIndividualDetail().Name.LastName : ""}
					></dc-input>
				</div>
			</div>
			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<ww-answer-string
					class=${((_k = props.q11e) == null ? void 0 : _k.isVisible("com_reg_name")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"com_reg_name"}
					.validation=${{ required: !((_l = props.q11e) == null ? void 0 : _l.isIndividual()), maxLength: 200 }}
				></ww-answer-string>
				<ww-answer-string
					class=${((_m = props.q11e) == null ? void 0 : _m.isVisible("comp_reg_num")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"comp_reg_num"}
					.validation=${{ required: (_n = props.q11e) == null ? void 0 : _n.isVisible("comp_reg_num"), maxLength: 200 }}
				></ww-answer-string>
				<ww-answer-money class=${((_o = props.q11e) == null ? void 0 : _o.isVisible("amount_of_cap")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"amount_of_cap"} .validation=${{ required: true }}></ww-answer-money>
				<ww-answer-date class=${((_p = props.q11e) == null ? void 0 : _p.isVisible("incorp_date")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"incorp_date"} .validation=${{ required: true }}></ww-answer-date>
			</div>
			<div>
				<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
					<dc-select
						.label=${i18next.t("Country of incorporation")}
						.selectedValues=${[(_q = props.q11e) == null ? void 0 : _q.getEstablishmentCountry()]}
						.dataSource=${props.worldCountries.map((country) => ({
    label: country.Name,
    value: country.Code
  }))}
						.filterable=${true}
						.readonly=${true}
					></dc-select>
					<dc-input .readonly=${true} .label=${i18next.t("City")} .value=${(_u = (_t = (_s = (_r = props.q11e) == null ? void 0 : _r.company) == null ? void 0 : _s.BusinessDetail) == null ? void 0 : _t.Address) == null ? void 0 : _u.City}></dc-input>
					<dc-input .readonly=${true} .label=${i18next.t("Street name")} .value=${(_y = (_x = (_w = (_v = props.q11e) == null ? void 0 : _v.company) == null ? void 0 : _w.BusinessDetail) == null ? void 0 : _x.Address) == null ? void 0 : _y.Street}></dc-input>
					<dc-input .readonly=${true} .label=${i18next.t("Street number")} .value=${(_C = (_B = (_A = (_z = props.q11e) == null ? void 0 : _z.company) == null ? void 0 : _A.BusinessDetail) == null ? void 0 : _B.Address) == null ? void 0 : _C.StreetNumber}></dc-input>
					<dc-input .readonly=${true} .label=${i18next.t("Postal/Zip code")} .value=${(_G = (_F = (_E = (_D = props.q11e) == null ? void 0 : _D.company) == null ? void 0 : _E.BusinessDetail) == null ? void 0 : _F.Address) == null ? void 0 : _G.Zip}></dc-input>
					<dc-input
						class=${((_K = (_J = (_I = (_H = props.q11e) == null ? void 0 : _H.company) == null ? void 0 : _I.BusinessDetail) == null ? void 0 : _J.Address) == null ? void 0 : _K.Province) === void 0 ? "hidden" : ""}
						.readonly=${true}
						.label=${i18next.t("Province")}
						.value=${(_O = (_N = (_M = (_L = props.q11e) == null ? void 0 : _L.company) == null ? void 0 : _M.BusinessDetail) == null ? void 0 : _N.Address) == null ? void 0 : _O.Province}
					></dc-input>
				</div>
			</div>
			<div class="grid lg:grid-cols-2 gap-4 mt-4 lg:w-1/2">
				<div class="lg:col-span-2">
					<ww-answer-fileref .q11e=${props.q11e} .questionId=${"business_cert"} .validation=${{ required: true }} .onUpload=${uploadFileToQuestion}></ww-answer-fileref>
				</div>
				<div class="lg:col-span-2">
					<ww-answer-fileref
						class=${((_P = props.q11e) == null ? void 0 : _P.isVisible("article_assoc")) ? "" : "hidden"}
						.q11e=${props.q11e}
						.questionId=${"article_assoc"}
						.validation=${{ required: true }}
						.onUpload=${uploadFileToQuestion}
					></ww-answer-fileref>
				</div>
				<div class=${`lg:col-span-2 ${((_Q = props.q11e) == null ? void 0 : _Q.isVisible("corresp_is_same")) ? "" : "hidden"}`}>
					<ww-answer-bool .q11e=${props.q11e} .questionId=${"corresp_is_same"} .validation=${{ required: true }}></ww-answer-bool>
				</div>
			</div>
			<div class=${`${((_R = props.q11e) == null ? void 0 : _R.isVisible("corresp_address")) ? "" : "hidden"}`}>
				<ww-answer-address
					.q11e=${props.q11e}
					.questionId=${"corresp_address"}
					.worldCountries=${props.worldCountries}
					.withCountry=${true}
					.validation=${{ required: true }}
				></ww-answer-address>
			</div>
		</span>
	`;
};
