export var PeriodFilterStatuses = /* @__PURE__ */ ((PeriodFilterStatuses2) => {
  PeriodFilterStatuses2["New"] = "New";
  PeriodFilterStatuses2["DataUploaded"] = "DataUploaded";
  PeriodFilterStatuses2["ValidationError"] = "ValidationError";
  PeriodFilterStatuses2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodFilterStatuses2["DocumentsGenerated"] = "DocumentsGenerated";
  PeriodFilterStatuses2["Error"] = "Error";
  PeriodFilterStatuses2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodFilterStatuses2["AcceptedByTa"] = "AcceptedByTa";
  PeriodFilterStatuses2["Submitted"] = "Submitted";
  PeriodFilterStatuses2["CorrectionNeeded"] = "CorrectionNeeded";
  return PeriodFilterStatuses2;
})(PeriodFilterStatuses || {});
