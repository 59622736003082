var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { PspInfo } from "./../PspInfo";
import { DateConverter } from "./../../../../converters/DateConverter";
import { PeriodVersion } from "./PeriodVersion";
export class Period {
  constructor(_data) {
    /**
     */
    __publicField(this, "CreatedAtUtc");
    /**
     */
    __publicField(this, "CurrentVersion");
    /**
     */
    __publicField(this, "EndDate");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "LastManualUpdateAtUtc");
    /**
     */
    __publicField(this, "Psp");
    /**
     */
    __publicField(this, "RegistrationIdentifier");
    /**
     */
    __publicField(this, "RegistrationIdentifierType");
    /**
     */
    __publicField(this, "ReportingCountry");
    /**
     */
    __publicField(this, "StartDate");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "SubmissionDueDate");
    /**
     */
    __publicField(this, "Versions");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Period
     */
    __publicField(this, "$type", "V1.Cesop.Periods.Period");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CreatedAtUtc = DateConverter.from(_data["CreatedAtUtc"]);
      if (_data["CurrentVersion"]) {
        const currentVersion_ = new PeriodVersion();
        this.CurrentVersion = currentVersion_.init(_data["CurrentVersion"]);
      }
      this.EndDate = DateConverter.from(_data["EndDate"]);
      this.Id = _data["Id"];
      this.LastManualUpdateAtUtc = DateConverter.from(_data["LastManualUpdateAtUtc"]);
      if (_data["Psp"]) {
        const psp_ = new PspInfo();
        this.Psp = psp_.init(_data["Psp"]);
      }
      this.RegistrationIdentifier = _data["RegistrationIdentifier"];
      this.RegistrationIdentifierType = PeriodRegistrationIdentifierType[_data["RegistrationIdentifierType"]];
      this.ReportingCountry = _data["ReportingCountry"];
      this.StartDate = DateConverter.from(_data["StartDate"]);
      this.Status = PeriodStatus[_data["Status"]];
      this.SubmissionDueDate = DateConverter.from(_data["SubmissionDueDate"]);
      if (_data["Versions"] && _data["Versions"].constructor === Array) {
        this.Versions = [];
        for (let item of _data["Versions"]) {
          const versions_ = new PeriodVersion();
          this.Versions.push(versions_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CreatedAtUtc"] = DateConverter.toIsoUtcString(this.CreatedAtUtc);
    _data["CurrentVersion"] = this.CurrentVersion ? this.CurrentVersion.toJSON() : void 0;
    _data["EndDate"] = DateConverter.toUtcDateString(this.EndDate);
    _data["Id"] = this.Id;
    _data["LastManualUpdateAtUtc"] = DateConverter.toIsoUtcString(this.LastManualUpdateAtUtc);
    _data["Psp"] = this.Psp ? this.Psp.toJSON() : void 0;
    _data["RegistrationIdentifier"] = this.RegistrationIdentifier;
    _data["RegistrationIdentifierType"] = PeriodRegistrationIdentifierType[this.RegistrationIdentifierType];
    _data["ReportingCountry"] = this.ReportingCountry;
    _data["StartDate"] = DateConverter.toUtcDateString(this.StartDate);
    _data["Status"] = PeriodStatus[this.Status];
    _data["SubmissionDueDate"] = DateConverter.toUtcDateString(this.SubmissionDueDate);
    if (this.Versions && this.Versions.constructor === Array) {
      _data["Versions"] = [];
      for (let item of this.Versions) {
        _data["Versions"].push(item.toJSON());
      }
    }
    return _data;
  }
}
export var PeriodRegistrationIdentifierType = /* @__PURE__ */ ((PeriodRegistrationIdentifierType2) => {
  PeriodRegistrationIdentifierType2["Bic"] = "Bic";
  PeriodRegistrationIdentifierType2["Other"] = "Other";
  return PeriodRegistrationIdentifierType2;
})(PeriodRegistrationIdentifierType || {});
export var PeriodStatus = /* @__PURE__ */ ((PeriodStatus2) => {
  PeriodStatus2["New"] = "New";
  PeriodStatus2["DataUploaded"] = "DataUploaded";
  PeriodStatus2["ValidationError"] = "ValidationError";
  PeriodStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodStatus2["DocumentsGenerated"] = "DocumentsGenerated";
  PeriodStatus2["Error"] = "Error";
  PeriodStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodStatus2["AcceptedByTa"] = "AcceptedByTa";
  PeriodStatus2["Submitted"] = "Submitted";
  PeriodStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  return PeriodStatus2;
})(PeriodStatus || {});
