var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
import { MessageResponseFactory } from "./MessageResponseFactory";
export class Message {
  constructor(_data) {
    /**
     */
    __publicField(this, "CorrelatedMessageId");
    /**
     */
    __publicField(this, "CreatedAtUtc");
    /**
     */
    __publicField(this, "ExternalReference");
    /**
     */
    __publicField(this, "FileName");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Responses");
    /**
     */
    __publicField(this, "Status");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Message
     */
    __publicField(this, "$type", "V1.Cesop.Periods.Message");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CorrelatedMessageId = _data["CorrelatedMessageId"];
      this.CreatedAtUtc = DateConverter.from(_data["CreatedAtUtc"]);
      this.ExternalReference = _data["ExternalReference"];
      this.FileName = _data["FileName"];
      this.Id = _data["Id"];
      if (_data["Responses"] && _data["Responses"].constructor === Array) {
        this.Responses = [];
        for (let item of _data["Responses"]) {
          const $typeResponses = item && item.$type;
          if ($typeResponses) {
            const responses_ = MessageResponseFactory.create($typeResponses);
            this.Responses.push(responses_.init(item));
          }
        }
      }
      this.Status = MessageStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CorrelatedMessageId"] = this.CorrelatedMessageId;
    _data["CreatedAtUtc"] = DateConverter.toIsoUtcString(this.CreatedAtUtc);
    _data["ExternalReference"] = this.ExternalReference;
    _data["FileName"] = this.FileName;
    _data["Id"] = this.Id;
    if (this.Responses && this.Responses.constructor === Array) {
      _data["Responses"] = [];
      for (let item of this.Responses) {
        _data["Responses"].push(item.toJSON());
      }
    }
    _data["Status"] = MessageStatus[this.Status];
    return _data;
  }
}
export var MessageStatus = /* @__PURE__ */ ((MessageStatus2) => {
  MessageStatus2["New"] = "New";
  MessageStatus2["Sending"] = "Sending";
  MessageStatus2["Sent"] = "Sent";
  MessageStatus2["ErrorAtTa"] = "ErrorAtTa";
  MessageStatus2["AcceptedByTa"] = "AcceptedByTa";
  MessageStatus2["ErrorAtCesop"] = "ErrorAtCesop";
  MessageStatus2["Submitted"] = "Submitted";
  MessageStatus2["TechnicalError"] = "TechnicalError";
  return MessageStatus2;
})(MessageStatus || {});
