export var PeriodVersionStatus = /* @__PURE__ */ ((PeriodVersionStatus2) => {
  PeriodVersionStatus2["New"] = "New";
  PeriodVersionStatus2["DataUploaded"] = "DataUploaded";
  PeriodVersionStatus2["ValidationError"] = "ValidationError";
  PeriodVersionStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodVersionStatus2["DocumentsGenerated"] = "DocumentsGenerated";
  PeriodVersionStatus2["Error"] = "Error";
  PeriodVersionStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodVersionStatus2["AcceptedByTa"] = "AcceptedByTa";
  PeriodVersionStatus2["Submitted"] = "Submitted";
  PeriodVersionStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  return PeriodVersionStatus2;
})(PeriodVersionStatus || {});
export var PeriodVersionUploadType = /* @__PURE__ */ ((PeriodVersionUploadType2) => {
  PeriodVersionUploadType2["Csv"] = "Csv";
  PeriodVersionUploadType2["Xml"] = "Xml";
  PeriodVersionUploadType2["Empty"] = "Empty";
  return PeriodVersionUploadType2;
})(PeriodVersionUploadType || {});
