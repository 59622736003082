var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Q11eValidator } from "GlobalShared/components/q11e/Q11eValidator";
import { useState, useQueryState, useEffect, useClient, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { useEUCountries, useWorldCountries, useBirthCountries } from "WebComponents/hooks/WebCustomHooks";
import { Questionnaire as QuestionnaireClient, Company as CompanyClient } from "WebServices/APIs/self/clients";
import { QUESTIONS } from "GlobalShared/components/q11e/questions";
import { QuestionnaireContext } from "GlobalShared/components/q11e/QuestionnaireContext";
import { convertAnswerEvent } from "GlobalShared/components/q11e/answer-utils";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { currentLocale } from "../../globals";
import { useConfirmModal } from "GlobalShared/components/modals/commonModals";
const QUESTIONNAIRE_STEPS = [i18next.t("Business Details"), i18next.t("Tax Details"), i18next.t("Country Details"), i18next.t("Personnel Details"), i18next.t("Banking Information")];
export function AdminQuestionnaire() {
  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };
  const previousStep = () => {
    setCurrentStep(currentStep > 0 ? currentStep - 1 : currentStep);
  };
  const onAnswerChanged = (e) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const answer = convertAnswerEvent(e);
        yield questionnaireClient.saveModification({ body: [answer] });
        setContext(context.getNewContextWithSetAnswer(answer));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const confirmFinalization = () => __async(this, null, function* () {
    const isConfirmed = yield confirmModal.confirm(i18next.t("questionnaire-finalization-confirmation"));
    return isConfirmed;
  });
  const finalize = () => __async(this, null, function* () {
    if (!(context == null ? void 0 : context.isReadonly())) {
      yield questionnaireClient.saveModification({ body: context.getAnswers() });
      window.setTimeout(() => {
        if (q11eValidationContext.validate(context).isValid()) {
          withErrorHandling(
            () => __async(this, null, function* () {
              const isConfirmed = yield confirmFinalization();
              if (!isConfirmed) {
                return;
              }
              yield questionnaireClient.finalizeQuestionnaire();
              setShowSuccessNotification(true);
              window.location.reload();
            }),
            (error) => {
              notifyError(error);
            }
          );
        } else {
          setShowValidationModal(true);
        }
      }, 100);
    }
  });
  const confirmModal = useConfirmModal({
    confirmText: i18next.t("Finalize Questionnaire"),
    cancelText: i18next.t("Cancel")
  });
  const [questionnaire, setQuestionnaire] = useState(void 0);
  const [company, setCompany] = useState(void 0);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [context, setContext] = useState(void 0);
  const worldCountries = useWorldCountries(currentLocale);
  const euCountries = useEUCountries(currentLocale);
  const birthCountries = useBirthCountries(currentLocale);
  const [currentStep, setCurrentStep] = useQueryState("step", 0);
  const questionnaireClient = useClient(QuestionnaireClient);
  const companyClient = useClient(CompanyClient);
  const [q11eValidator, setQ11eValidator] = useState(new Q11eValidator(context));
  const q11eValidationContext = useValidationContext(q11eValidator);
  const [showSuccessNotification, setShowSuccessNotification] = useQueryState("qSuccess", false);
  const init = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const questionnaireResponse = yield questionnaireClient.getQuestionnaire();
        setQuestionnaire(questionnaireResponse.data);
        const companyResponse = yield companyClient.getCompanyDetail({
          locale: currentLocale
        });
        setCompany(companyResponse.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  useEffect(init, []);
  useEffect(() => {
    if (showSuccessNotification) {
      notifySuccess(i18next.t("questionnaire-finalization-success"));
      setShowSuccessNotification(false);
    }
  }, []);
  useEffect(() => {
    q11eValidationContext.validate(context);
  }, [q11eValidator]);
  useEffect(() => {
    if (context !== void 0) {
      setQ11eValidator(new Q11eValidator(context));
    }
  }, [context]);
  useEffect(() => {
    if (questionnaire && euCountries && company) {
      setContext(new QuestionnaireContext(questionnaire, false, euCountries, QUESTIONS, company, false));
    }
  }, [questionnaire, euCountries, company]);
  const templateValidationModal = () => {
    return html`
			<div style="max-width: 300px">
				<dc-modal
					.visible=${showValidationModal}
					@close=${() => setShowValidationModal(false)}
					.header=${i18next.t("Validation errors")}
					.content=${html`<ul>
						${q11eValidationContext.validationResult.getFailures().map((failure) => {
      var _a;
      return html`<li class="text-red-400">${(_a = QUESTIONS.filter((question) => question.Id === failure.propertyName)[0]) == null ? void 0 : _a.Label}: ${failure.message}</li>`;
    })}
					</ul>`}
				>
				</dc-modal>
			</div>
		`;
  };
  const templateBusinessDetails = () => {
    return html`<ww-q11e-business-details .q11e=${context} .worldCountries=${worldCountries} .euCountries=${euCountries} @change=${onAnswerChanged}></ww-q11e-business-details>`;
  };
  const templateTaxDetails = () => {
    return html`<ww-q11e-tax-details .q11e=${context} .worldCountries=${worldCountries} .euCountries=${euCountries} @change=${onAnswerChanged}></ww-q11e-tax-details>`;
  };
  const templateCountryDetails = () => {
    return html`<ww-q11e-country-details .q11e=${context} .worldCountries=${worldCountries} .euCountries=${euCountries} @change=${onAnswerChanged}></ww-q11e-country-details>`;
  };
  const templatePersonnelDetails = () => {
    return html`<ww-answer-business-owners .q11e=${context} .questionId=${"business_owner"} @change=${onAnswerChanged}> </ww-answer-business-owners>
			<ww-answer-legal-representatives
				.q11e=${context}
				.worldCountries=${worldCountries}
				.birthCountries=${birthCountries}
				.questionId=${"legal_rep"}
				@change=${onAnswerChanged}
			></ww-answer-legal-representatives>
			<ww-answer-beneficial-owners .q11e=${context} .questionId=${"beneficial_owner"} .worldCountries=${worldCountries} @change=${onAnswerChanged}></ww-answer-beneficial-owners>`;
  };
  const templateBankingInformation = () => {
    return html`<ww-q11e-banking-information .q11e=${context} @change=${onAnswerChanged} .worldCountries=${worldCountries}></ww-q11e-banking-information>`;
  };
  const finalizeButtonTemplate = () => {
    const disabledClasses = (context == null ? void 0 : context.isReadonly()) ? "opacity-50 cursor-pointer pointer-events-none" : "";
    return html`
			<div class="w-full text-right relative">
				<button class="absolute right-0 top-6 btn btn-primary ${disabledClasses}" @click=${() => finalize()}>
					${(context == null ? void 0 : context.isReadonly()) ? i18next.t("Finalized") : i18next.t("Confirm and Finalize")}
				</button>
			</div>
		`;
  };
  return html`<div class="my-2">
		<div class="m-auto max-w-5xl">
			<div class="flex justify-around w-full my-4">
				<dc-step-progressbar
					.items=${QUESTIONNAIRE_STEPS}
					.activeIndex=${currentStep}
					.inactiveClickable=${true}
					@click=${(e) => {
    setCurrentStep(e.detail.step);
  }}
				></dc-step-progressbar>
			</div>
			${finalizeButtonTemplate()} ${currentStep === 0 ? templateBusinessDetails() : ""} ${currentStep === 1 ? templateTaxDetails() : ""} ${currentStep === 2 ? templateCountryDetails() : ""}
			${currentStep === 3 ? templatePersonnelDetails() : ""} ${currentStep === 4 ? templateBankingInformation() : ""}
			<div class="mt-8 flex w-full justify-between">
				<button
					class="px-4 h-12 border-2 border-brand-primary-light rounded-md text-brand-primary text-sm bg-white font-semibold ${currentStep === 0 ? "invisible" : ""}"
					@click=${previousStep}
				>
					${i18next.t("Previous")}
				</button>
				<button class="btn btn-primary h-12 ${currentStep === 4 ? "invisible" : ""}" @click=${nextStep}>${i18next.t("Next")}</button>
			</div>
		</div>
		${templateValidationModal()} ${confirmModal.mainTemplate()}
	</div>`;
}
