var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../../converters/DateConverter";
export class PeriodSearchResult {
  constructor(_data) {
    /**
     */
    __publicField(this, "CorrectionDeadline");
    /**
     */
    __publicField(this, "EndDate");
    /**
     */
    __publicField(this, "HasSubmissionProof");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "NumberOfTransactionFiles");
    /**
     */
    __publicField(this, "NumberOfTransactions");
    /**
     */
    __publicField(this, "Period");
    /**
     */
    __publicField(this, "PspName");
    /**
     */
    __publicField(this, "RegistrationIdentifier");
    /**
     */
    __publicField(this, "ReportingCountry");
    /**
     */
    __publicField(this, "StartDate");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "UploadType");
    /**
     */
    __publicField(this, "Version");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PeriodSearchResult
     */
    __publicField(this, "$type", "V1.Cesop.Periods.Search.PeriodSearchResult");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CorrectionDeadline = DateConverter.from(_data["CorrectionDeadline"]);
      this.EndDate = DateConverter.from(_data["EndDate"]);
      this.HasSubmissionProof = _data["HasSubmissionProof"];
      this.Id = _data["Id"];
      this.NumberOfTransactionFiles = _data["NumberOfTransactionFiles"];
      this.NumberOfTransactions = _data["NumberOfTransactions"];
      this.Period = _data["Period"];
      this.PspName = _data["PspName"];
      this.RegistrationIdentifier = _data["RegistrationIdentifier"];
      this.ReportingCountry = _data["ReportingCountry"];
      this.StartDate = DateConverter.from(_data["StartDate"]);
      this.Status = PeriodSearchResultStatus[_data["Status"]];
      this.UploadType = PeriodSearchResultUploadType[_data["UploadType"]];
      this.Version = _data["Version"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CorrectionDeadline"] = DateConverter.toIsoUtcString(this.CorrectionDeadline);
    _data["EndDate"] = DateConverter.toUtcDateString(this.EndDate);
    _data["HasSubmissionProof"] = this.HasSubmissionProof;
    _data["Id"] = this.Id;
    _data["NumberOfTransactionFiles"] = this.NumberOfTransactionFiles;
    _data["NumberOfTransactions"] = this.NumberOfTransactions;
    _data["Period"] = this.Period;
    _data["PspName"] = this.PspName;
    _data["RegistrationIdentifier"] = this.RegistrationIdentifier;
    _data["ReportingCountry"] = this.ReportingCountry;
    _data["StartDate"] = DateConverter.toUtcDateString(this.StartDate);
    _data["Status"] = PeriodSearchResultStatus[this.Status];
    _data["UploadType"] = PeriodSearchResultUploadType[this.UploadType];
    _data["Version"] = this.Version;
    return _data;
  }
}
export var PeriodSearchResultStatus = /* @__PURE__ */ ((PeriodSearchResultStatus2) => {
  PeriodSearchResultStatus2["New"] = "New";
  PeriodSearchResultStatus2["DataUploaded"] = "DataUploaded";
  PeriodSearchResultStatus2["ValidationError"] = "ValidationError";
  PeriodSearchResultStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodSearchResultStatus2["DocumentsGenerated"] = "DocumentsGenerated";
  PeriodSearchResultStatus2["Error"] = "Error";
  PeriodSearchResultStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodSearchResultStatus2["AcceptedByTa"] = "AcceptedByTa";
  PeriodSearchResultStatus2["Submitted"] = "Submitted";
  PeriodSearchResultStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  return PeriodSearchResultStatus2;
})(PeriodSearchResultStatus || {});
export var PeriodSearchResultUploadType = /* @__PURE__ */ ((PeriodSearchResultUploadType2) => {
  PeriodSearchResultUploadType2["Csv"] = "Csv";
  PeriodSearchResultUploadType2["Xml"] = "Xml";
  PeriodSearchResultUploadType2["Empty"] = "Empty";
  return PeriodSearchResultUploadType2;
})(PeriodSearchResultUploadType || {});
