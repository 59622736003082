import { ZippedFileSizeLimitUnit } from "AdminServices/APIs/self/models/V1/Cesop/Configuration/ZippedFileSizeLimit";
import { FileSizeLimitUnit } from "WebServices/APIs/self/models/V1/Cesop/Configuration/FileSizeLimit";
import { TransactionCountOrFileSizeLimitUnit } from "WebServices/APIs/self/models/V1/Cesop/Configuration/TransactionCountOrFileSizeLimit";
import { PeriodStatus } from "WebServices/APIs/self/models/V1/Cesop/Periods/PeriodAbstraction";
import { SourceFileStatus } from "WebServices/APIs/self/models/V1/Cesop/Periods/SourceFile";
import i18next from "i18next";
export const PeriodStatuses = [
  { label: i18next.t("Awaiting data"), value: "New" },
  { label: i18next.t("Data uploaded"), value: "DataUploaded" },
  { label: i18next.t("Validation error"), value: "ValidationError" },
  { label: i18next.t("Document generation in progress"), value: "DocumentGenerationInProgress" },
  { label: i18next.t("Ready to Submit"), value: "DocumentsGenerated" },
  { label: i18next.t("Error"), value: "Error" },
  { label: i18next.t("Submitted to Tax Authority"), value: "SubmissionInProgress" },
  { label: i18next.t("Accepted by Tax Authority"), value: "AcceptedByTa" },
  { label: i18next.t("Accepted by CESOP"), value: "Submitted" },
  { label: i18next.t("Correction Needed"), value: "CorrectionNeeded" }
];
export const MessageStatuses = [
  { label: i18next.t("New"), value: "New" },
  { label: i18next.t("Sending"), value: "Sending" },
  { label: i18next.t("Sent"), value: "Sent" },
  { label: i18next.t("Error at TA"), value: "ErrorAtTa" },
  { label: i18next.t("Accepted by TA"), value: "AcceptedByTa" },
  { label: i18next.t("Error at CESOP"), value: "ErrorAtCesop" },
  { label: i18next.t("Accepted by CESOP"), value: "Submitted" },
  { label: i18next.t("Technical error"), value: "TechnicalError" }
];
export const getMessageStatusLabel = (status) => {
  const item = MessageStatuses.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const getPeriodStatusLabel = (status) => {
  const item = PeriodStatuses.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const BEFORE_FINALIZE_PERIOD_STATUSES = [PeriodStatus.New, PeriodStatus.ValidationError];
export const SOURCE_FILE_VALIDATION_ENDED_STATUSES = [SourceFileStatus.Validated, SourceFileStatus.ValidationError];
export const resolveUnit = (unit) => {
  switch (unit) {
    case ZippedFileSizeLimitUnit.GigaByte:
    case FileSizeLimitUnit.GigaByte:
    case TransactionCountOrFileSizeLimitUnit.GigaByte:
      return "GB";
    default:
      return "MB";
  }
};
