var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { useState } from "GlobalShared/haunted/CustomHooks";
import { notifySuccess } from "GlobalShared/helpers/errorHelper";
const FieldNames = {
  "Tax Code": "Tax Code",
  "Reporting Country": "Reporting Country",
  "Transaction Type": "Transaction Type",
  "VAT Rate": "VAT Rate",
  "Type of Goods": "Type of Goods",
  "Reverse Charge %": "Reverse Charge %",
  "Actions": "Actions"
};
export function useDemoSetupTaxCodeStep(props) {
  const resetAll = () => {
    setIsModalOpen(false);
    setModalState({
      id: 0,
      taxCode: "",
      reportingCountry: "",
      transactionType: "",
      vatRate: "",
      typeOfGoods: "",
      reverseCharge: ""
    });
  };
  const handleEditRow = (item) => {
    setIsEditMode(true);
    setModalState(item);
    setIsModalOpen(true);
  };
  const saveEditedRow = () => {
    const index = gridRows.findIndex((row) => row.id === modalState.id);
    gridRows[index] = __spreadValues({}, modalState);
    setGridRows([...gridRows]);
    resetAll();
    setIsEditMode(false);
  };
  const addNewRow = () => {
    setGridRows([
      ...gridRows,
      __spreadProps(__spreadValues({}, modalState), {
        id: gridRows.length
      })
    ]);
  };
  const [gridRows, setGridRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalState, setModalState] = useState({
    id: 0,
    taxCode: "",
    reportingCountry: "",
    transactionType: "",
    vatRate: "",
    typeOfGoods: "",
    reverseCharge: ""
  });
  const gridTemplate = () => {
    const vm = {
      columns: [
        {
          field: FieldNames["Tax Code"],
          label: "Tax Code",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames["Reporting Country"],
          label: "Reporting Country",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames["Transaction Type"],
          label: "Transaction Type",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames["VAT Rate"],
          label: "VAT Rate",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames["Type of Goods"],
          label: "Type of Goods",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames["Reverse Charge %"],
          label: "Reverse Carge %",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames.Actions,
          label: "Actions",
          sortable: false,
          filterable: false,
          columnType: "string"
        }
      ],
      data: gridRows,
      cellTemplate: (index, field) => {
        var _a, _b, _c, _d, _e, _f;
        const item = gridRows[index];
        if (field === FieldNames["Tax Code"]) {
          return (_a = item.taxCode) != null ? _a : "";
        } else if (field === FieldNames["Reporting Country"]) {
          return (_b = item.reportingCountry) != null ? _b : "";
        } else if (field === FieldNames["Transaction Type"]) {
          return (_c = item.transactionType) != null ? _c : "";
        } else if (field === FieldNames["VAT Rate"]) {
          return (_d = item.vatRate) != null ? _d : "";
        } else if (field === FieldNames["Type of Goods"]) {
          return (_e = item.typeOfGoods) != null ? _e : "";
        } else if (field === FieldNames["Reverse Charge %"]) {
          return (_f = item.reverseCharge) != null ? _f : "";
        } else if (field === FieldNames.Actions) {
          return html` <div class="m-1">${PrimaryButton({ class: "w-24", text: "Edit", size: "small", onClick: () => handleEditRow(item) })}</div> `;
        } else {
          return "";
        }
      },
      appliedFilters: [],
      paging: {
        pageable: true,
        pageIndex: 1,
        pageSize: 100,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: gridRows.length
      }
    };
    return html` <dc-table .vm=${vm}></dc-table>`;
  };
  const modalContentTemplate = () => html`
		<div class="flex flex-col gap-8 py-4 px-6">
			<div class="flex">
				<div class="border-r-2 border-solid border-brand-primary pl-4 pr-8">
					<dc-input .label=${"Tax Code"} .value=${modalState.taxCode} @change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { taxCode: e.detail.value }))}></dc-input>
				</div>
				<div class="flex flex-col gap-4 pl-8">
					<dc-select
						.label=${"Reporting Country"}
						.dataSource=${[
    "Hungary",
    "Germany",
    "France",
    "Italy",
    "Spain",
    "Portugal",
    "Netherlands",
    "Belgium",
    "Austria",
    "Poland",
    "Czech Republic",
    "Slovakia",
    "Slovenia",
    "Croatia",
    "Romania",
    "Bulgaria",
    "Greece",
    "Cyprus",
    "Malta",
    "Luxembourg",
    "Estonia",
    "Latvia",
    "Lithuania"
  ]}
						.selectedValues=${modalState.reportingCountry}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { reportingCountry: e.detail.selectedValue }))}
					></dc-select>
					<dc-select
						.label=${"Transaction Type"}
						.dataSource=${["Sales", "Purchase"]}
						.selectedValues=${modalState.transactionType}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { transactionType: e.detail.selectedValue }))}
					></dc-select>
					<dc-select
						.label=${"VAT Rate"}
						.dataSource=${["5%", "10%", "15%"]}
						.selectedValues=${modalState.vatRate}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { vatRate: e.detail.selectedValue }))}
					></dc-select>
					<dc-select
						.label=${"Type of Goods"}
						.dataSource=${["Goods", "Services"]}
						.selectedValues=${modalState.typeOfGoods}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { typeOfGoods: e.detail.selectedValue }))}
					></dc-select>
					<dc-input
						.label=${"Reverse Charge Percentage"}
						.value=${modalState.reverseCharge}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { reverseCharge: e.detail.value }))}
					></dc-input>
				</div>
			</div>

			<div class="flex gap-4 flex-wrap">
				${SecondaryButton({
    class: "w-32",
    text: "Cancel",
    onClick: () => {
      resetAll();
      setIsEditMode(false);
    }
  })}
				${PrimaryButton({
    class: "w-32",
    text: isEditMode ? "Save" : "Add",
    onClick: () => {
      if (isEditMode) {
        saveEditedRow();
      } else {
        addNewRow();
      }
      resetAll();
    }
  })}
			</div>
		</div>
	`;
  const template = () => html`
		<div class="m-8 flex flex-col gap-4">
			<div>${templateTitle(i18next.t("Setup Tax Codes"))}</div>
			<div>${i18next.t("Add and configure your own tax codes")}</div>

			${PrimaryButton({ class: "w-48 my-6", text: i18next.t("Add Tax Code"), onClick: () => setIsModalOpen(true) })} ${gridTemplate()}

			<div class="flex w-full flex-wrap justify-between gap-4">
				${SecondaryButton({ class: "w-32 my-6", text: i18next.t("Previous"), onClick: () => props.setStep(1) })}
				${PrimaryButton({ class: "w-48 my-6", disabled: gridRows.length === 0, text: i18next.t("Save and Finalize"), onClick: () => notifySuccess(i18next.t("Saved successfully!")) })}
			</div>
		</div>

		<dc-modal
			class="tax-code-modal"
			.customClass=${"max-w-[95%] top-[10%] w-[600px]"}
			.visible=${isModalOpen}
			@close=${() => {
    resetAll();
    setIsEditMode(false);
  }}
			.header=${isEditMode ? "Edit Tax Code" : "Tax Code"}
			.content=${modalContentTemplate()}
		>
		</dc-modal>
	`;
  return { template };
}
