export var PeriodStatus = /* @__PURE__ */ ((PeriodStatus2) => {
  PeriodStatus2["New"] = "New";
  PeriodStatus2["DataUploaded"] = "DataUploaded";
  PeriodStatus2["ValidationError"] = "ValidationError";
  PeriodStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodStatus2["DocumentsGenerated"] = "DocumentsGenerated";
  PeriodStatus2["Error"] = "Error";
  PeriodStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodStatus2["AcceptedByTa"] = "AcceptedByTa";
  PeriodStatus2["Submitted"] = "Submitted";
  PeriodStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  return PeriodStatus2;
})(PeriodStatus || {});
export var PeriodRegistrationIdentifierType = /* @__PURE__ */ ((PeriodRegistrationIdentifierType2) => {
  PeriodRegistrationIdentifierType2["Bic"] = "Bic";
  PeriodRegistrationIdentifierType2["Other"] = "Other";
  return PeriodRegistrationIdentifierType2;
})(PeriodRegistrationIdentifierType || {});
