var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { GenerationConfiguration as GenerationConfigurationModel } from "./../models/V1/GenerationConfiguration/GenerationConfiguration";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class GenerationConfiguration {
  /**
   * Creates an instance of GenerationConfiguration.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof GenerationConfiguration
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param CreateRq_ _createRq
   * @returns Promise<CreateRq_>
   * @memberof GenerationConfiguration
   */
  create(pCreateRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pCreateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: GenerationConfigurationModel },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetRq_ _getRq
   * @returns Promise<GetRq_>
   * @memberof GenerationConfiguration
   */
  get(pGetRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration/{companyId}", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pGetRq.companyId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: GenerationConfigurationModel },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateRq_ _updateRq
   * @returns Promise<UpdateRq_>
   * @memberof GenerationConfiguration
   */
  update(pUpdateRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration/{companyId}", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pUpdateRq.companyId, required: true }]);
    client.addBody({ value: pUpdateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
