import { html } from "lit-html";
import { uploadFileToQuestion } from "GlobalShared/helpers/questionnaireHelper";
import i18next from "i18next";
import { templateTitle } from "GlobalShared/templates/commons";
import "./q11e-add-modal.scss";
export const observedAttributes = ["q11e", "worldCountries", "euCountries"];
export const useShadowDOM = false;
export const name = "ww-q11e-tax-details";
const DEFAULTS = {
  worldCountries: [],
  euCountries: []
};
export const Component = (host) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A;
  const props = {
    q11e: host.q11e,
    worldCountries: host.worldCountries ? host.worldCountries : DEFAULTS.worldCountries,
    euCountries: host.euCountries ? host.euCountries : DEFAULTS.euCountries
  };
  const getEstablishmentVatNumber = () => {
    var _a2, _b2, _c2;
    const detail = ((_a2 = props.q11e) == null ? void 0 : _a2.isIndividual()) ? (_b2 = props.q11e) == null ? void 0 : _b2.getIndividualDetail() : (_c2 = props.q11e) == null ? void 0 : _c2.getCompanyDetail();
    if (!detail) {
      return "";
    }
    const fullVatNumber = detail.VatNumber;
    if (fullVatNumber.startsWith(detail.EstablishmentCountryCode)) {
      return fullVatNumber.substring(2);
    }
    return fullVatNumber;
  };
  return html`
		<span>
			<div class="mt-8">${templateTitle(i18next.t("Other VAT Related Questions"))}</div>
			<div class="mt-8">
				<ww-answer-bool
					class="lg:col-span-2 ${((_a = props.q11e) == null ? void 0 : _a.isVisible("other_vats")) ? "" : "hidden"}"
					.q11e=${props.q11e}
					.questionId=${"other_vats"}
					.validation=${{ required: true }}
				></ww-answer-bool>
				<div class="mt-4">
					<ww-answer-existing-vat-numbers .q11e=${props.q11e} .questionId=${"exs_vats"} .euCountries=${props.euCountries}></ww-answer-existing-vat-numbers>
				</div>
			</div>
			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<div class="${((_b = props.q11e) == null ? void 0 : _b.hasVatService()) ? "" : " hidden"}">
					<label class="form-label"
						>${i18next.t("VAT Number of country of incorporation")}
						<dc-tooltip .label=${i18next.t("Businesses outside of the EU should provide a local Tax ID in the country of establishment.")}></dc-tooltip>
					</label>

					<div class="flex">
						<dc-input class="w-16" .readonly=${true} .value=${(_c = props.q11e) == null ? void 0 : _c.getEstablishmentCountry()}></dc-input>
						<dc-input class="lg:col-span-2" .readonly=${true} .value=${getEstablishmentVatNumber()}></dc-input>
					</div>
				</div>
			</div>
			<div class="grid md:grid-cols-1 gap-4 mt-4 items-end lg:w-1/2">
				<ww-answer-fileref
					class=${`lg:col-span-2 ${((_d = props.q11e) == null ? void 0 : _d.hasVatService()) ? "" : "hidden"}`}
					.q11e=${props.q11e}
					.questionId=${"estab_vat_cert"}
					.validation=${{ required: (_e = props.q11e) == null ? void 0 : _e.isVisible("estab_vat_cert") }}
					.onUpload=${uploadFileToQuestion}
				></ww-answer-fileref>
			</div>
			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<ww-answer-string
					class=${((_f = props.q11e) == null ? void 0 : _f.isVisible("tax_auth_name")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"tax_auth_name"}
					.validation=${{ required: (_g = props.q11e) == null ? void 0 : _g.isVisible("tax_auth_name") }}
				></ww-answer-string>
			</div>
			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<ww-answer-bool class="lg:col-span-2" .q11e=${props.q11e} .questionId=${"is_on_stock"} .validation=${{ required: true }}></ww-answer-bool>
				<ww-answer-string class=${((_h = props.q11e) == null ? void 0 : _h.isVisible("stock_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"stock_name"} .validation=${{ required: true }}></ww-answer-string>
				<ww-answer-string
					class=${((_i = props.q11e) == null ? void 0 : _i.isVisible("ticker_symbol")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"ticker_symbol"}
					.validation=${{ required: true }}
				></ww-answer-string>
			</div>
			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<ww-answer-string
					class=${((_j = props.q11e) == null ? void 0 : _j.isVisible("mp_store_name")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"mp_store_name"}
					.validation=${{ required: true }}
				></ww-answer-string>
				<ww-answer-string class=${((_k = props.q11e) == null ? void 0 : _k.isVisible("sell_id")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"sell_id"} .validation=${{ required: true }}></ww-answer-string>
				<ww-answer-multistring class=${((_l = props.q11e) == null ? void 0 : _l.isVisible("mp_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"mp_name"} .validation=${{ required: true }}></ww-answer-multistring>
				<ww-answer-string class=${((_m = props.q11e) == null ? void 0 : _m.isVisible("web_link")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"web_link"} .validation=${{ required: true }}></ww-answer-string>
			</div>

			<div class=${`mt-8 ${((_n = props.q11e) == null ? void 0 : _n.hasIossService()) ? "" : "hidden"}`}>${templateTitle(i18next.t("IOSS Registration Related Questions"))}</div>
			<div class="grid md:grid-cols-2 gap-4 mt-2 items-end">
				<ww-answer-string
					class=${((_o = props.q11e) == null ? void 0 : _o.isVisible("ioss_web_link")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"ioss_web_link"}
					.validation=${{ required: (_p = props.q11e) == null ? void 0 : _p.isVisible("ioss_web_link") }}
				></ww-answer-string>
				<ww-answer-string
					class=${((_q = props.q11e) == null ? void 0 : _q.isVisible("trading_name")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"trading_name"}
					.validation=${{ required: false }}
				></ww-answer-string>
				<ww-answer-multistring
					class=${((_r = props.q11e) == null ? void 0 : _r.isVisible("ioss_exs_ioss")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"ioss_exs_ioss"}
					.validation=${{ required: (_s = props.q11e) == null ? void 0 : _s.isVisible("ioss_exs_ioss") }}
				></ww-answer-multistring>
			</div>
			<ww-answer-fixed-establishments
				class=${((_t = props.q11e) == null ? void 0 : _t.isVisible("ioss_fixed_estab")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.euCountries=${props.euCountries}
				.questionId=${"ioss_fixed_estab"}
			></ww-answer-fixed-establishments>

			<div class=${`mt-8 mb-2 ${((_u = props.q11e) == null ? void 0 : _u.hasVatService()) && !((_v = props.q11e) == null ? void 0 : _v.hasOssService()) || ((_w = props.q11e) == null ? void 0 : _w.isOssTakeOver()) ? "" : "hidden"}`}>
				${templateTitle(i18next.t("OSS Information"))}
			</div>
			<ww-answer-bool
				class=${`lg:col-span-2 ${((_x = props.q11e) == null ? void 0 : _x.isVisible("oss_has_registration")) ? "" : "hidden"}`}
				.q11e=${props.q11e}
				.questionId=${"oss_has_registration"}
				.validation=${{ required: true }}
			></ww-answer-bool>
			<ww-answer-oss-details
				class=${((_y = props.q11e) == null ? void 0 : _y.isVisible("oss_details")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.euCountries=${props.euCountries}
				.questionId=${"oss_details"}
			></ww-answer-oss-details>

			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<ww-answer-string
					class=${((_z = props.q11e) == null ? void 0 : _z.isVisible("oss_takeover_registration_number")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"oss_takeover_registration_number"}
					.validation=${{ required: true }}
				></ww-answer-string>
				<ww-answer-date
					class=${((_A = props.q11e) == null ? void 0 : _A.isVisible("oss_takeover_registration_date")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"oss_takeover_registration_date"}
					.validation=${{ required: true }}
				></ww-answer-date>
			</div>
		</span>
	`;
};
