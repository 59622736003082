var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { PossibleStatusChange } from "./../models/V1/Company/VatRegistration/PossibleStatusChange";
import { DataSourceField } from "./../models/V1/DocGenerator/DataSourceField";
import { PredefinedBankAccount } from "./../models/V1/CompanyInfo/VatRegistration/PredefinedBankAccount";
import { VatRegistrationStatusChangeHistory } from "./../models/V1/Company/VatRegistration/VatRegistrationStatusChangeHistory";
import { VatRegSearchResult } from "./../models/V1/Company/VatRegistration/Search/VatRegSearchResult";
export class VatRegistrations {
  /**
   * Creates an instance of VatRegistrations.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof VatRegistrations
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param ChangeBankAccountRq_ _changeBankAccountRq
   * @returns Promise<ChangeBankAccountRq_>
   * @memberof VatRegistrations
   */
  changeBankAccount(pChangeBankAccountRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/BankAccount",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeBankAccountRq.companyId, required: true },
      { name: "vatRegId", value: pChangeBankAccountRq.vatRegId, required: true }
    ]);
    client.addBody({ value: pChangeBankAccountRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeFrequenciesRq_ _changeFrequenciesRq
   * @returns Promise<ChangeFrequenciesRq_>
   * @memberof VatRegistrations
   */
  changeFrequencies(pChangeFrequenciesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/ChangeFrequencies",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeFrequenciesRq.companyId, required: true },
      { name: "vatRegId", value: pChangeFrequenciesRq.vatRegId, required: true }
    ]);
    client.addBody({ value: pChangeFrequenciesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangePaymentReferenceRq_ _changePaymentReferenceRq
   * @returns Promise<ChangePaymentReferenceRq_>
   * @memberof VatRegistrations
   */
  changePaymentReference(pChangePaymentReferenceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registrations/{vatRegistrationId}/paymentreference",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangePaymentReferenceRq.companyId, required: true },
      { name: "vatRegistrationId", value: pChangePaymentReferenceRq.vatRegistrationId, required: true }
    ]);
    client.addBody({ value: pChangePaymentReferenceRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStartDateRq_ _changeStartDateRq
   * @returns Promise<ChangeStartDateRq_>
   * @memberof VatRegistrations
   */
  changeStartDate(pChangeStartDateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registrations/{vatRegistrationId}/startDate",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeStartDateRq.companyId, required: true },
      { name: "vatRegistrationId", value: pChangeStartDateRq.vatRegistrationId, required: true }
    ]);
    client.addBody({ value: pChangeStartDateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeTaxIdentifierRq_ _changeTaxIdentifierRq
   * @returns Promise<ChangeTaxIdentifierRq_>
   * @memberof VatRegistrations
   */
  changeTaxIdentifier(pChangeTaxIdentifierRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registrations/{vatRegistrationId}/taxidentifier",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeTaxIdentifierRq.companyId, required: true },
      { name: "vatRegistrationId", value: pChangeTaxIdentifierRq.vatRegistrationId, required: true }
    ]);
    client.addBody({ value: pChangeTaxIdentifierRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeTaxOfficeIdRq_ _changeTaxOfficeIdRq
   * @returns Promise<ChangeTaxOfficeIdRq_>
   * @memberof VatRegistrations
   */
  changeTaxOfficeId(pChangeTaxOfficeIdRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registrations/{vatRegistrationId}/taxofficeid",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeTaxOfficeIdRq.companyId, required: true },
      { name: "vatRegistrationId", value: pChangeTaxOfficeIdRq.vatRegistrationId, required: true }
    ]);
    client.addBody({ value: pChangeTaxOfficeIdRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeVatRegStatusRq_ _changeVatRegStatusRq
   * @returns Promise<ChangeVatRegStatusRq_>
   * @memberof VatRegistrations
   */
  changeVatRegStatus(pChangeVatRegStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/status",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeVatRegStatusRq.companyId, required: true },
      { name: "vatRegId", value: pChangeVatRegStatusRq.vatRegId, required: true }
    ]);
    client.addBody({ value: pChangeVatRegStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateAdministratorRq_ _updateAdministratorRq
   * @returns Promise<UpdateAdministratorRq_>
   * @memberof VatRegistrations
   */
  updateAdministrator(pUpdateAdministratorRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registrations/{vatRegistrationId}/administrator",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateAdministratorRq.companyId, required: true },
      { name: "vatRegistrationId", value: pUpdateAdministratorRq.vatRegistrationId, required: true }
    ]);
    client.addBody({ value: pUpdateAdministratorRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteAdministratorRq_ _deleteAdministratorRq
   * @returns Promise<DeleteAdministratorRq_>
   * @memberof VatRegistrations
   */
  deleteAdministrator(pDeleteAdministratorRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registrations/{vatRegistrationId}/administrator",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDeleteAdministratorRq.companyId, required: true },
      { name: "vatRegistrationId", value: pDeleteAdministratorRq.vatRegistrationId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadDeregistrationDocumentRq_ _downloadDeregistrationDocumentRq
   * @returns Promise<DownloadDeregistrationDocumentRq_>
   * @memberof VatRegistrations
   */
  downloadDeregistrationDocument(pDownloadDeregistrationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/deregistrationDocument",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadDeregistrationDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pDownloadDeregistrationDocumentRq.vatRegId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadPoaRevokedConfirmationDocumentRq_ _downloadPoaRevokedConfirmationDocumentRq
   * @returns Promise<DownloadPoaRevokedConfirmationDocumentRq_>
   * @memberof VatRegistrations
   */
  downloadPoaRevokedConfirmationDocument(pDownloadPoaRevokedConfirmationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/poarevokeconfirmationdocument",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadPoaRevokedConfirmationDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pDownloadPoaRevokedConfirmationDocumentRq.vatRegId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadVatCertificateRq_ _downloadVatCertificateRq
   * @returns Promise<DownloadVatCertificateRq_>
   * @memberof VatRegistrations
   */
  downloadVatCertificate(pDownloadVatCertificateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/certificate/{certificateType}",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadVatCertificateRq.companyId, required: true },
      { name: "vatRegId", value: pDownloadVatCertificateRq.vatRegId, required: true },
      { name: "certificateType", value: pDownloadVatCertificateRq.certificateType, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadVatRegDocumentRq_ _downloadVatRegDocumentRq
   * @returns Promise<DownloadVatRegDocumentRq_>
   * @memberof VatRegistrations
   */
  downloadVatRegDocument(pDownloadVatRegDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/template/{templateId}",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addQueryParams([{ name: "type", value: pDownloadVatRegDocumentRq.type }]);
    client.addPathParams([
      { name: "companyId", value: pDownloadVatRegDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pDownloadVatRegDocumentRq.vatRegId, required: true },
      { name: "templateId", value: pDownloadVatRegDocumentRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportVatRegistrationsRq_ _exportVatRegistrationsRq
   * @returns Promise<ExportVatRegistrationsRq_>
   * @memberof VatRegistrations
   */
  exportVatRegistrations(pExportVatRegistrationsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRegistrations/export", "POST", "application/json; charset=UTF-8", "text/csv, application/json", options);
    client.addBody({ value: pExportVatRegistrationsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GenerateVatRegDocumentRq_ _generateVatRegDocumentRq
   * @returns Promise<GenerateVatRegDocumentRq_>
   * @memberof VatRegistrations
   */
  generateVatRegDocument(pGenerateVatRegDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/template/{templateId}/creation",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGenerateVatRegDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pGenerateVatRegDocumentRq.vatRegId, required: true },
      { name: "templateId", value: pGenerateVatRegDocumentRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetAvailableStatusChangesRq_ _getAvailableStatusChangesRq
   * @returns Promise<GetAvailableStatusChangesRq_>
   * @memberof VatRegistrations
   */
  getAvailableStatusChanges(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/registrations/statuses",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PossibleStatusChange, isArray: true },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetDataSourceRq_ _getDataSourceRq
   * @returns Promise<GetDataSourceRq_>
   * @memberof VatRegistrations
   */
  getDataSource(pGetDataSourceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/countries/{vatRegCountry}/template/{templateId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGetDataSourceRq.companyId, required: true },
      { name: "vatRegCountry", value: pGetDataSourceRq.vatRegCountry, required: true },
      { name: "templateId", value: pGetDataSourceRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: DataSourceField, isArray: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetEmptyDataSourceRq_ _getEmptyDataSourceRq
   * @returns Promise<GetEmptyDataSourceRq_>
   * @memberof VatRegistrations
   */
  getEmptyDataSource(pGetEmptyDataSourceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/countries/{vatRegCountry}/template/{templateId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "vatRegCountry", value: pGetEmptyDataSourceRq.vatRegCountry, required: true },
      { name: "templateId", value: pGetEmptyDataSourceRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: DataSourceField, isArray: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetPredefinedBankAccountsRq_ _getPredefinedBankAccountsRq
   * @returns Promise<GetPredefinedBankAccountsRq_>
   * @memberof VatRegistrations
   */
  getPredefinedBankAccounts(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRegistrations/PredefinedBankAccounts", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: PredefinedBankAccount, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetStatusChangeHistoryRq_ _getStatusChangeHistoryRq
   * @returns Promise<GetStatusChangeHistoryRq_>
   * @memberof VatRegistrations
   */
  getStatusChangeHistory(pGetStatusChangeHistoryRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRegistrations/{registrationId}/StatusChangeHistory", "GET", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "registrationId", value: pGetStatusChangeHistoryRq.registrationId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: VatRegistrationStatusChangeHistory, isArray: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchVatRegistrationRq_ _searchVatRegistrationRq
   * @returns Promise<SearchVatRegistrationRq_>
   * @memberof VatRegistrations
   */
  searchVatRegistration(pSearchVatRegistrationRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRegistrations/registration", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSearchVatRegistrationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: VatRegSearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadDocumentRq_ _uploadDocumentRq
   * @returns Promise<UploadDocumentRq_>
   * @memberof VatRegistrations
   */
  uploadDocument(pUploadDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/document",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pUploadDocumentRq.vatRegId, required: true }
    ]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadDocumentRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSignedVatRegDocumentRq_ _uploadSignedVatRegDocumentRq
   * @returns Promise<UploadSignedVatRegDocumentRq_>
   * @memberof VatRegistrations
   */
  uploadSignedVatRegDocument(pUploadSignedVatRegDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistrations/companies/{companyId}/registration/{vatRegId}/template/{templateId}/signed",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadSignedVatRegDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pUploadSignedVatRegDocumentRq.vatRegId, required: true },
      { name: "templateId", value: pUploadSignedVatRegDocumentRq.templateId, required: true }
    ]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadSignedVatRegDocumentRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
export var DownloadVatCertificateCertificateType = /* @__PURE__ */ ((DownloadVatCertificateCertificateType2) => {
  DownloadVatCertificateCertificateType2["Local"] = "Local";
  DownloadVatCertificateCertificateType2["Eu"] = "Eu";
  return DownloadVatCertificateCertificateType2;
})(DownloadVatCertificateCertificateType || {});
export var DownloadVatRegDocumentType = /* @__PURE__ */ ((DownloadVatRegDocumentType2) => {
  DownloadVatRegDocumentType2["Unsigned"] = "Unsigned";
  DownloadVatRegDocumentType2["Signed"] = "Signed";
  return DownloadVatRegDocumentType2;
})(DownloadVatRegDocumentType || {});
