import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { ONLY_XLSX_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
export const useSapExcelUpload = (props) => {
  const htmlSapExcelUpload = (mode) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const currentSapExcelUploadJob = (_a = props.currentUploadJobs) == null ? void 0 : _a.find((job) => job.type === "SapExcel");
    const isSapExcelErrorFileSameAsValidFile = ((_c = (_b = props.currentComplianceUpload) == null ? void 0 : _b.SapExcelFile) == null ? void 0 : _c.Reference) === ((_d = currentSapExcelUploadJob == null ? void 0 : currentSapExcelUploadJob.latestUploadedFile) == null ? void 0 : _d.Reference);
    const isSapExcelFileSuccessFullyUploaded = ((_f = (_e = props.currentComplianceUpload) == null ? void 0 : _e.SapExcelFile) == null ? void 0 : _f.Reference) && !isSapExcelErrorFileSameAsValidFile;
    return html`
			<div class="flex flex-col max-w-[600px]">
				<div class="text-xl font-bold truncate mb-2">${i18next.t("Upload Transactions")}</div>
				<dc-dropzone-select
					class=${classNames("h-40 rounded-xl bg-gray-100 block", {
      "hover:border-2 hover:border-dashed hover:border-gray-500": !props.hasAnyOngoingDataProcessingJob() && mode !== "Readonly"
    })}
					.accept=${ONLY_XLSX_ACCEPT_LIST}
					.uploadButtonLabel=${i18next.t("Select File")}
					.label=${i18next.t("Drop file here to upload")}
					.dragOverText=${i18next.t("Drop file here to upload")}
					.onFileUpload=${(files) => props.uploadFileToBlob(files[0], "SapExcel")}
					.readonly=${props.hasAnyOngoingDataProcessingJob()}
				></dc-dropzone-select>
				${props.htmlFileUploadStatus("SapExcel", isSapExcelErrorFileSameAsValidFile, (_g = props.currentComplianceUpload) == null ? void 0 : _g.SapExcelFile, isSapExcelFileSuccessFullyUploaded)}
				${props.isDataProcessingJobOngoingForUploadType("SapExcel") ? html`<dc-loader2 .isLoading=${true}></dc-loader2> ` : ""}
			</div>
		`;
  };
  return {
    htmlSapExcelUpload
  };
};
