import { html } from "lit-html";
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { uploadFileToQuestion } from "GlobalShared/helpers/questionnaireHelper";
export const observedAttributes = ["q11e"];
export const useShadowDOM = false;
export const name = "ww-q11e-banking-information";
export const Component = (host) => {
  var _a;
  const props = {
    q11e: host.q11e,
    worldCountries: (_a = host.worldCountries) != null ? _a : []
  };
  const hasVisibleBankInformationField = () => {
    var _a2, _b, _c, _d, _e;
    return ((_a2 = props.q11e) == null ? void 0 : _a2.isVisible("has_pl_bank")) || ((_b = props.q11e) == null ? void 0 : _b.isVisible("pl_bank")) || ((_c = props.q11e) == null ? void 0 : _c.isVisible("other_bank")) || ((_d = props.q11e) == null ? void 0 : _d.isVisible("bank_cert")) || ((_e = props.q11e) == null ? void 0 : _e.isVisible("es_sepa_bank"));
  };
  const templateBankInfo = () => {
    var _a2, _b, _c, _d, _e, _f, _g, _h;
    return html`
			<div>
				<div class="my-8 ${((_a2 = props.q11e) == null ? void 0 : _a2.isVisible("other_bank")) ? "" : "hidden"}">${templateTitle(i18next.t("Banking Information"))}</div>
				<ww-answer-bank-information
					class=${((_b = props.q11e) == null ? void 0 : _b.isVisible("other_bank")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"other_bank"}
					.worldCountries=${props.worldCountries}
				></ww-answer-bank-information>

				<div class="my-8 ${((_c = props.q11e) == null ? void 0 : _c.isVisible("has_pl_bank")) ? "" : "hidden"}">${templateTitle(i18next.t("Polish Banking Information"))}</div>
				<ww-answer-bool class=${((_d = props.q11e) == null ? void 0 : _d.isVisible("has_pl_bank")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"has_pl_bank"} .validation=${{ required: true }}></ww-answer-bool>
				<div class="my-8 ${((_e = props.q11e) == null ? void 0 : _e.isVisible("pl_bank")) ? "" : "hidden"}">
					<label class="text-lg font-bold"> ${i18next.t("Please add the Polish bank details")} </label>
				</div>

				<ww-answer-bank-information
					class=${((_f = props.q11e) == null ? void 0 : _f.isVisible("pl_bank")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"pl_bank"}
					.withDefaultCountry=${"PL"}
					.worldCountries=${props.worldCountries}
				></ww-answer-bank-information>

				<ww-answer-fileref
					class=${((_g = props.q11e) == null ? void 0 : _g.isVisible("bank_cert")) ? "block w-1/3 md:w-1/2" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"bank_cert"}
					.onUpload=${uploadFileToQuestion}
					.validation=${{ required: true }}
				></ww-answer-fileref>
				<div class="my-4">
					<ww-answer-bool class=${((_h = props.q11e) == null ? void 0 : _h.isVisible("es_sepa_bank")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"es_sepa_bank"} .validation=${{ required: true }}></ww-answer-bool>
				</div>
			</div>
		`;
  };
  return html`
		${props.q11e !== void 0 ? hasVisibleBankInformationField() ? templateBankInfo() : html`
						<div>
							<div class="my-8">${templateTitle(i18next.t("Banking Information"))}</div>
							${i18next.t("q11e-banking-information-nobankinginformation")}
						</div>
				  ` : ""}
	`;
};
