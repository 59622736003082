var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { EXCEL_SEPARATOR_COMMAND } from "GlobalShared/common/Constants";
export const simulateDownload = (file) => {
  var _a;
  const fileName = (_a = file.name) == null ? void 0 : _a.replace(/\"/g, "");
  const downloadUrl = window.URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = fileName;
  a.click();
};
export const parseCSVFile = (file, delimiter = ",") => __async(void 0, null, function* () {
  let content = yield parseContent(file);
  if (content.trim().length === 0) {
    return [];
  }
  if (content.startsWith(EXCEL_SEPARATOR_COMMAND)) {
    content = content.substr(EXCEL_SEPARATOR_COMMAND.length);
  }
  return convertCSVToArray(content, delimiter);
});
export const parseContent = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function(e) {
      resolve(e.target.result);
    };
    reader.onerror = function(e) {
      reject(e);
    };
    reader.readAsText(file, "ISO-8859-1");
  });
};
export const downloadFile = (content, fileName, mimeType) => {
  const a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType
      }),
      fileName
    );
  } else if (URL && "download" in a) {
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType
      })
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href = "data:application/octet-stream," + encodeURIComponent(content);
  }
};
export function convertCSVToArray(strData, delimiter) {
  if (!strData) {
    return [[]];
  }
  delimiter = delimiter || ",";
  const objPattern = new RegExp(
    // Delimiters.
    "(\\" + delimiter + '|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^"\\' + delimiter + "\\r\\n]*))",
    "gi"
  );
  const arrData = [[]];
  let arrMatches;
  while (arrMatches = objPattern.exec(strData.trim())) {
    const strMatchedDelimiter = arrMatches[1];
    if (strMatchedDelimiter.length && strMatchedDelimiter !== delimiter) {
      arrData.push([]);
    }
    let strMatchedValue;
    if (arrMatches[2]) {
      strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    } else {
      strMatchedValue = arrMatches[3];
    }
    arrData[arrData.length - 1].push(strMatchedValue);
  }
  return arrData;
}
export function convertArrayToCSV(data, delimiter) {
  return data.map((row) => {
    return row.map((item) => {
      if (!item) {
        return "";
      }
      if (item.trim().length !== item.length || item.indexOf(`"`) > -1 || item.indexOf(delimiter) > -1) {
        return `"${item.replace(/\"/g, `""`)}"`;
      }
      return item;
    }).join(delimiter);
  }).join("\r\n");
}
const generateAccepts = (types) => {
  return types.map((t) => `.${t.toLowerCase()}`);
};
export const Q11E_DOC_ACCEPT_LIST = generateAccepts(["JPG", "JPEG", "PNG", "PDF"]);
export const RETURN_PROOF_DOC_ACCEPT_LIST = generateAccepts(["JPG", "JPEG", "PNG", "PDF"]);
export const NOT_TO_BE_SUBMITTED_PROOF_DOC_ACCEPT_LIST = generateAccepts(["JPG", "JPEG", "PNG", "XLSX", "DOCX", "MSG"]);
export const RETURN_REJECTION_DOC_ACCEPT_LIST = generateAccepts(["JPG", "JPEG", "PNG", "PDF", "XLSX", "XLS"]);
export const RETURN_DOC_ACCEPT_LIST = generateAccepts(["PDF", "XLSX", "XML", "PNG", "CSV", "TXT"]);
export const AMAZON_TRANSACTION_ACCEPT_LIST = generateAccepts(["CSV", "TSV", "TXT"]);
export const ONLY_PDF_ACCEPT_LIST = generateAccepts(["PDF"]);
export const ONLY_CSV_ACCEPT_LIST = generateAccepts(["CSV"]);
export const ONLY_XLSX_ACCEPT_LIST = generateAccepts(["XLSX"]);
export const ONLY_TXT_ACCEPT_LIST = generateAccepts(["TXT"]);
